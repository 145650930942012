import React, { useEffect } from "react";
import { Spinner } from "reactstrap";
import { isEmpty } from "lodash";

import Layout from "./Layout";
import useRouter from "../../hooks/useRouter";
import useQuery from "../../hooks/useQuery";
import routes from "../../routes";
import useSupportProfile from "../../hooks/useSupportProfile";

export default function Loading() {
  const { routeTo } = useRouter();
  const supportProfile = useSupportProfile();
  const { redirect } = useQuery();

  useEffect(() => {
    console.log({ redirect });
    if (!isEmpty(redirect)) {
      routeTo(redirect);
    } else if (supportProfile) {
      routeTo(routes.discover);
    }
  }, [redirect, supportProfile, routeTo]);

  return (
    <Layout.Container>
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-uppercase">Loading your account...</div>
        <Spinner color="primary" size="3" />
      </div>
    </Layout.Container>
  );
}
