import React from "react";

import MapDisplay from "./MapDisplay";
import { WithAddress } from "../types";

import useGeocoding from "../hooks/useGeocoding";
import formatAddress from "../utils/formatAddress";

import L from "leaflet";
import marker from "leaflet/dist/images/marker-icon.png";
import { Marker } from "react-leaflet";

const MARKER_ICON = L.icon({
  iconUrl: marker,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
});

interface Props {
  heading?: React.ReactNode;
  address: WithAddress;
}

export default function AddressPreview({ heading = null, address }: Props) {
  const formattedAddress = formatAddress(address);
  const result = useGeocoding(formattedAddress);

  return result ? (
    <div>
      {heading}
      <div className="rounded shadow-sm">
        <MapDisplay
          center={result.center}
          marker={result.type === "house"}
          zoom={10}
        >
          <Marker
            key={address.id}
            radius={4}
            position={result.center}
            icon={MARKER_ICON}
          ></Marker>
        </MapDisplay>
      </div>

      <div className="text-muted text-center" style={{ fontSize: 8 }}>
        {result.license}
      </div>
    </div>
  ) : null;
}
