import React from "react";

import Layout from "./Layout";
import useIdToken from "../../hooks/useIdToken";
import { Alert } from "reactstrap";

export default function Profile() {
  const token = useIdToken();

  return (
    <Layout.Container>
      <div className="mb-3">
        <h3>Account ID Token</h3>
        <Alert color="danger">
          Do not share this ID token with anyone; it secures your account.
        </Alert>
      </div>
      <code>{token}</code>
    </Layout.Container>
  );
}
