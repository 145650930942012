import React from "react";

import { SmallGroupType } from "../types";
import IfSupport from "./helpers/IfSupport";
import { Badge, Button } from "reactstrap";
import useApproveDocument from "../hooks/useApproveDocument";
import If from "./helpers/If";
import routes from "../routes";
import formatAddress from "../utils/formatAddress";
import useAuth from "../hooks/useAuth";
import isSupportAccount from "../utils/isSupportAccount";
import useSupportProfile from "../hooks/useSupportProfile";
import IfNotSupport from "./helpers/IfNotSupport";

interface Props {
  smallGroup: SmallGroupType;
}

export default function SmallGroup({ smallGroup }: Props) {
  const { user } = useAuth();
  const supportProfile = useSupportProfile();
  const approveDocument = useApproveDocument();
  const onApprove = () => approveDocument(smallGroup);

  const isOwner = user && smallGroup.uid === user.uid;

  const canEdit = isOwner || isSupportAccount(supportProfile);

  return (
    <div className="mb-3 py-1 border-top">
      <div className="d-flex flex-column flex-lg-row align-items-start justify-content-between">
        <div>
          <a
            href={routes.groups.view(smallGroup.id)}
            className="font-weight-bold"
          >
            {smallGroup.name}
          </a>
          <If test={isOwner}>
            <small>
              <Badge color="secondary" className="ml-2">
                Owner
              </Badge>
            </small>
          </If>
          <IfNotSupport>
            <If test={!smallGroup.approved}>
              <small>
                <Badge color="success" className="ml-2">
                  Pending approval
                </Badge>
              </small>
            </If>
          </IfNotSupport>
          <div>
            <small>{formatAddress(smallGroup)}</small>
          </div>
        </div>

        <div className="mt-1">
          <IfSupport>
            <If test={!smallGroup.approved}>
              <Button
                onClick={onApprove}
                color="success"
                outline
                size="sm"
                className="mr-2"
              >
                Approve
              </Button>
            </If>
          </IfSupport>
          <If test={canEdit}>
            <Button
              color="dark"
              outline
              size="sm"
              href={routes.groups.edit(smallGroup.id)}
            >
              Edit
            </Button>
          </If>
        </div>
      </div>
    </div>
  );
}
