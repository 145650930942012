import React, { Fragment } from "react";
import { Navbar, NavItem, NavLink, Nav } from "reactstrap";
import Icon from "@mdi/react";
import { size } from "lodash";
import SecondaryNavigation from "./SecondaryNavigation";
import { useLocation } from "react-router";
import If from "./helpers/If";
import routes from "../routes";
import useNotifications from "../hooks/useNotifications";

interface SidebarItemProps {
  icon: string;
  href: string;
  label: string;
  count: number;
}

const countBadgeStyle = {
  width: 16,
  height: 16,
  lineHeight: "16px",
  fontSize: 10,
  position: "absolute" as "absolute",
  top: -2,
  right: -4,
};

function FooterItem({ icon, href, label, count }: SidebarItemProps) {
  const { pathname } = useLocation();
  const isActive = href === pathname;
  return (
    <NavItem active={isActive} className="flex-shrink-0">
      <NavLink href={href} className="d-flex flex-column align-items-center">
        <div className="position-relative">
          <Icon path={icon} size={1.0} />

          <If test={count}>
            <div
              className="ml-1 d-flex align-items-center justify-content-center rounded-circle bg-danger text-white border border-white"
              style={countBadgeStyle}
            >
              {count}
            </div>
          </If>
        </div>

        <small className="">{label}</small>
      </NavLink>
    </NavItem>
  );
}

export default function FooterNavigation() {
  const { notifications } = useNotifications();

  const countOfNotifications = Math.min(size(notifications), 9);
  const counts = {
    [routes.myNotifications]: countOfNotifications,
  };

  return (
    <Fragment>
      <div className="d-md-none border-top border-light">
        <Navbar expand light color="white" className="p-0">
          <Nav justified className="w-100" navbar>
            {SecondaryNavigation.filter((item) => !item.isDesktopOnly).map(
              (props, index) => (
                <FooterItem key={index} {...props} count={counts[props.href]} />
              )
            )}
          </Nav>
        </Navbar>
      </div>
    </Fragment>
  );
}
