import React from "react";

import MainLayout from "./MainLayout";
import SmallGroupForm from "../components/SmallGroupForm";
import { SmallGroupType } from "../types";
import { useParams } from "react-router";
import useSmallGroup from "../hooks/useSmallGroup";
import { Spinner } from "reactstrap";
import If from "../components/helpers/If";
import useUpdateSmallGroup from "../hooks/useUpdateSmallGroup";
import { useNotification } from "../components/Notification";

export default function EditSmallGroupPage() {
  const { group } = useParams();
  const { smallGroup, isLoading } = useSmallGroup(group);
  const { notify } = useNotification();

  const updateSmallGroup = useUpdateSmallGroup();
  const onUpdate = async (updates: SmallGroupType) => {
    if (smallGroup && smallGroup.id) {
      await updateSmallGroup(smallGroup.id, updates);

      notify("Your small group has been updated", "success");
    }
  };

  return (
    <MainLayout>
      <h1 className="h4">Edit Small Group</h1>
      <If test={isLoading}>
        <div className="h-50 d-flex align-items-center justify-content-center">
          <Spinner color="primary" />
        </div>
      </If>
      <If test={!isLoading}>
        <SmallGroupForm
          smallGroup={smallGroup}
          readonly={false}
          onUpdate={onUpdate}
          updateLabel="Update"
        />
      </If>
    </MainLayout>
  );
}
