import React, { Fragment, FormEvent, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { get } from "lodash";

import useAuth from "../hooks/useAuth";
import getDisplayName from "../utils/getDisplayName";
import { ProfileType } from "../types";
import BusyButton from "./BusyButton";
import useFormInputState from "../hooks/useFormInputState";
import AvatarPreview from "./AvatarPreview";
import routes from "../routes";
import {
  mdiEmailOutline,
  mdiRadioHandheld,
  mdiShieldLock,
  mdiTelegram,
} from "@mdi/js";
import Icon from "@mdi/react";
import If from "./helpers/If";
import classes from "../utils/classes";

type OnUpdate = (
  updatedProfile: ProfileType,
  avatarFile: File | undefined
) => Promise<void>;

interface Props {
  readonly: boolean;
  profile: ProfileType;
  onUpdate: OnUpdate;
  canPreview?: boolean;
  updateLabel?: string;
}

const DEFAULT_NAME = "";
const DEFAULT_BIO = "";
const DEFAULT_CITY = "";
const DEFAULT_STATE = "";
const DEFAULT_ZIP_CODE = "";
const DEFAULT_AVATAR_FILENAME = "";

export default function BiographyForm({
  readonly = true,
  profile: initialProfile,
  onUpdate,
  canPreview = true,
  updateLabel = "Update",
}: Props) {
  const { user } = useAuth();
  const [isUpdating, setUpdating] = useState(false);

  const avatarFilename = get(
    initialProfile,
    "avatarFilename",
    DEFAULT_AVATAR_FILENAME
  );
  const [avatarFile, onChangeAvatarFile] = useState<File>();

  const [name, onChangeName] = useFormInputState(
    get(initialProfile, "name", DEFAULT_NAME)
  );
  const [bio, onChangeBio] = useFormInputState(
    get(initialProfile, "bio", DEFAULT_BIO)
  );
  const [email, onChangeEmail] = useFormInputState(
    get(initialProfile, "email", "")
  );
  const [radio, onChangeRadio] = useFormInputState(
    get(initialProfile, "radio", "")
  );
  const [telegram, onChangeTelegram] = useFormInputState(
    get(initialProfile, "telegram", "")
  );
  const [city, onChangeCity] = useFormInputState(
    get(initialProfile, "city", DEFAULT_CITY)
  );
  const [state, onChangeState] = useFormInputState(
    get(initialProfile, "state", DEFAULT_STATE)
  );
  const [zipCode, onChangeZipCode] = useFormInputState(
    get(initialProfile, "zipCode", DEFAULT_ZIP_CODE)
  );

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setUpdating(true);

    await onUpdate(
      {
        name,
        bio,
        email,
        radio,
        telegram,
        city,
        state,
        zipCode,
      },
      avatarFile
    );

    setUpdating(false);
  };

  return (
    <Fragment>
      <Alert color="info" className="shadow-sm">
        <div className="d-flex align-items-center">
          <Icon path={mdiShieldLock} size={2} />
          <div className="ml-3">
            <strong>
              Your profile will only be visible to signed in users.
            </strong>
            <br />
            <small>
              Please share what you're comfortable with; nothing is required.
            </small>
          </div>
        </div>
      </Alert>

      <div className="mt-5" />

      <Form onSubmit={onSubmit}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <AvatarPreview
              onChange={onChangeAvatarFile}
              avatarFilename={avatarFilename}
            />
            <div className="ml-3 text-muted">
              <em>Choose a profile picture.</em>
            </div>
          </div>

          <If test={canPreview}>
            <a href={routes.user(getDisplayName(user))}>
              Preview your profile
              <span className="d-none d-lg-inline">
                <br />
                as others will see it.
              </span>
            </a>
          </If>
        </div>

        <div className="mb-3" />

        <FormGroup>
          <Label for="name">Your username</Label>
          <Input type="text" readOnly={true} value={getDisplayName(user)} />
        </FormGroup>

        <FormGroup>
          <Label for="name">Your name</Label>
          <Input
            type="text"
            placeholder="Please enter your name"
            readOnly={readonly}
            value={name}
            onChange={onChangeName}
          />
        </FormGroup>

        <FormGroup className="mb-2">
          <Label for="location">Your city / county</Label>
          <Input
            type="text"
            placeholder="City or county"
            readOnly={readonly}
            value={city}
            onChange={onChangeCity}
          />
        </FormGroup>

        <FormGroup row>
          <Col size={8}>
            <Input
              type="text"
              placeholder="State"
              readOnly={readonly}
              value={state}
              onChange={onChangeState}
            />
          </Col>
          <Col size={4}>
            <Input
              type="text"
              placeholder="Zip Code"
              readOnly={readonly}
              value={zipCode}
              onChange={onChangeZipCode}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Label for="email">Your contact information</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon path={mdiEmailOutline} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="email"
              placeholder="Your email"
              readOnly={readonly}
              value={email}
              onChange={onChangeEmail}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon path={mdiRadioHandheld} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Your radio contact (call sign and frequency)..."
              readOnly={readonly}
              value={radio}
              onChange={onChangeRadio}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon path={mdiTelegram} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Your Telegram username..."
              readOnly={readonly}
              value={telegram}
              onChange={onChangeTelegram}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <Label for="name">About yourself</Label>
          <Input
            type="textarea"
            rows={10}
            placeholder="Please share a little about yourself..."
            readOnly={readonly}
            value={bio}
            onChange={onChangeBio}
            style={{ minHeight: 200 }}
          />
        </FormGroup>

        <div className="d-flex">
          <BusyButton
            color="success"
            className={classes({ "ml-auto": !canPreview }, "shadow")}
            busyLabel="Updating"
            isBusy={isUpdating}
          >
            {updateLabel}
          </BusyButton>

          <If test={canPreview}>
            <Button color="link" href={routes.user(getDisplayName(user))}>
              Preview
            </Button>
          </If>
        </div>
      </Form>
    </Fragment>
  );
}
