import React, { Fragment } from "react";
import { isEmpty } from "lodash";

import { Maybe, SmallGroupType } from "../types";
import AddressPreview from "./AddressPreview";
import If from "./helpers/If";
import formatAddress from "../utils/formatAddress";
import Formatted from "./Formatted";
import NotFound from "../pages/NotFound";
import useStartConversation from "../hooks/useStartConversation";
import { Alert, Button } from "reactstrap";
import useAuth from "../hooks/useAuth";
import Icon from "@mdi/react";
import { mdiCheckCircleOutline } from "@mdi/js";
import ContactInformationDisplay from "./ContactInformationDisplay";

interface Props {
  smallGroup: Maybe<SmallGroupType>;
}

export default function SmallGroup({ smallGroup }: Props) {
  const { user } = useAuth();
  const startConversation = useStartConversation();

  const onContact = () => {
    startConversation([smallGroup?.uid, user?.uid]);
  };

  if (!smallGroup) {
    return <NotFound />;
  }

  const locationHeading = (
    <Fragment>
      <h2 className="h6 mb-1">Group Location</h2>
      <div>{formatAddress(smallGroup)}</div>
    </Fragment>
  );

  return (
    <Fragment>
      <Alert color="info" className="shadow">
        <div className="d-flex align-items-center">
          <Icon path={mdiCheckCircleOutline} size={2} />
          <div className="ml-3">
            <strong>Pending approval</strong>
            <br />
            <small>This small group is currently pending staff review.</small>
          </div>
        </div>
      </Alert>

      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="h4">{smallGroup.name}</h1>

          <Button size="sm" color="dark" onClick={onContact}>
            Send Message
          </Button>
        </div>

        <h2 className="h6 mb-1">About</h2>
        <Formatted>{smallGroup.description}</Formatted>

        <div className="mt-3" />

        <ContactInformationDisplay
          title={<h2 className="h6 mb-1">Contact</h2>}
          contact={smallGroup}
        />

        <If test={isEmpty(smallGroup.description)}>
          <em>We haven't shared anything else quite yet...</em>
        </If>

        <div className="mt-3" />

        <AddressPreview address={smallGroup} heading={locationHeading} />
      </div>
    </Fragment>
  );
}
