import { isNumber, isString } from "lodash";
import moment from "moment";

export default function toMoment(datetime: any) {
  if (isNumber(datetime)) {
    return moment(datetime);
  }
  if (isString(datetime)) {
    const parsed = parseInt(datetime, 10);
    if (!isNaN(parsed)) {
      return moment(parsed * 1000);
    }
  }
  if (datetime?.toDate) {
    return moment(datetime.toDate());
  }
  return moment(datetime);
}
