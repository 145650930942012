import { useHistory } from "react-router-dom";
import { useEffect, useMemo } from "react";

export default function useRouter() {
  const history = useHistory();
  const router = useMemo(
    () => ({
      routeTo(path) {
        console.log(`Route to: ${path}`);
        history.push(path);
      },
    }),
    [history]
  );
  return router;
}

export function RouterConnection() {
  const { routeTo } = useRouter();

  useEffect(() => {
    const listener = (event) => {
      const href = event.target.getAttribute("href");
      if (href && href.startsWith("/")) {
        console.log(`Intercepting href="${href}"`);
        // Don't follow the link
        event.preventDefault();
        // Route to the target href instead
        routeTo(href);
      } else if (process.env.NODE_ENV === "development") {
        console.warn(`Did not intercept href=${href}`);
      }
    };
    document.addEventListener("click", listener, false);

    return () => {
      document.removeEventListener("click", listener, false);
    };
  }, [routeTo]);

  return null;
}
