import { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import { isEmpty } from "lodash";
import useErrorProvider from "./useErrorProvider";

export default function useAssetFromStorage(filename: string | undefined) {
  const [url, setUrl] = useState();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (!isEmpty(filename)) {
      const storage = firebase.storage();
      storage
        .ref(filename)
        .getDownloadURL()
        .then((downloadUrl) => setUrl(downloadUrl))
        .catch((error) => reportError(error, "useAssetFromStorage"));
    }
  }, [filename, reportError]);
  return url;
}
