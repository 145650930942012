import React from "react";
import Icon from "@mdi/react";
import { mdiCalendarStar } from "@mdi/js";

import MainLayout from "./MainLayout";
import { DESCRIPTIONS } from "../components/SecondaryNavigation";

export default function EventsPage() {
  return (
    <MainLayout>
      <div className="d-flex flex-column h-100">
        <h1 className="h4 mb-0">Events</h1>
        <div className="mb-3 text-muted lead">{DESCRIPTIONS.events}</div>
        <div className="d-flex flex-column align-items-center justify-content-center h-100 text-muted">
          <Icon path={mdiCalendarStar} size={4} className="text-muted" />
          <div>
            <em>Coming soon...</em>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
