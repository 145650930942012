import React, { Fragment, useEffect } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import Icon from "@mdi/react";
import { map } from "lodash";

import useAuth from "../hooks/useAuth";
import useRouter from "../hooks/useRouter";
import routes from "../routes";
import Layout from "./Layout";
import FrequentlyAskedQuestions from "../components/FrequentlyAskedQuestions";
import Introduction from "../components/Introduction";
import SecurityAndTrust from "../components/SecurityAndTrust";
import { FEATURED } from "../components/SecondaryNavigation";
import If from "../components/helpers/If";
import classes from "../utils/classes";
import Logo from "../components/Logo";
import AppName from "../components/AppName";

interface MemberBenefitProps {
  label: string;
  description: string;
  icon: any;
  index: number;
}

function MemberBenefit({
  label,
  description,
  icon,
  index,
}: MemberBenefitProps) {
  const isLeft = index % 2 === 0;
  const containerClassName = classes(
    {
      "justify-content-start": isLeft,
      "justify-content-end": !isLeft,
    },
    "rounded bg-white border border-dark shadow-lg",
    "my-3 p-3",
    "d-flex align-items-start"
  );
  const colProps = isLeft
    ? {
        md: { size: 8, offset: 1 },
        lg: { size: 6, offset: 2 },
      }
    : {
        md: { size: 8, offset: 3 },
        lg: { size: 6, offset: 4 },
      };
  const iconElement = (
    <div className="text-muted">
      <Icon path={icon} size={2} />
    </div>
  );
  return (
    <Row>
      <Col {...colProps}>
        <div className={containerClassName}>
          <If test={isLeft}>{iconElement}</If>
          <div className={isLeft ? "text-left ml-3" : "text-right mr-3"}>
            <h3 className="h5">{label}</h3>
            <p className="mb-0">{description}</p>
          </div>
          <If test={!isLeft}>{iconElement}</If>
        </div>
      </Col>
    </Row>
  );
}

export default function Landing() {
  const { user } = useAuth();
  const { routeTo } = useRouter();

  useEffect(() => {
    if (user) {
      routeTo(routes.dashboard);
    }
  });

  const existingLoginContent = (
    <Fragment>
      <h4>Current Members</h4>
      <p>For members who have already created an account.</p>
      <Button href={routes.account.signin} block outline color="primary">
        Sign In
      </Button>
    </Fragment>
  );

  return (
    <Layout>
      <div className="min-vh-100 bg-white d-flex align-items-center justify-content-center flex-column">
        <div
          style={{
            width: "100%",
            backgroundImage: "url(/img/together.jpg)",
            backgroundSize: "cover",
            minHeight: "40vh",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
          }}
          className="d-flex align-items-center"
        />

        <div className="shadow-inverted w-100 border-top border-dark">
          <Container>
            <Row>
              <Col>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
                  <div className="pt-5">
                    <h1 className="h4 mb-0 text-center">
                      Find and join a Mutual Assistance Group in your area
                    </h1>

                    <div className="my-3 mx-auto w-100 border-top border-dark" />

                    <h2 className="d-block d-md-none mb-0 h1 font-weight-light text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <Logo size={1} />
                        <div className="ml-1">
                          <AppName />
                        </div>
                      </div>
                    </h2>
                    <h2 className="d-none d-md-block display-4 mb-0 text-center">
                      <div className="d-flex align-items-center justify-content-center">
                        <Logo size={2} />
                        <div className="ml-1">
                          <AppName />
                        </div>
                      </div>
                    </h2>

                    <div className="my-3 mx-auto w-100 border-top border-dark" />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="my-5">
              <Col md={{ size: 8, offset: 2 }}>
                <Introduction />
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="py-5 border-top border-bottom border-dark shadow-inset"
          style={{
            width: "100%",
            backgroundImage: "url(/img/cabin.jpg)",
            backgroundSize: "cover",
            minHeight: "50vh",
            backgroundPositionX: "center",
            backgroundPositionY: "center",
          }}
        >
          <Container>
            <Row className="my-3">
              <Col md={{ size: 8, offset: 2 }}>
                <h2 className="h4 text-center">
                  Member-exclusive access includes
                </h2>
              </Col>
            </Row>
            {map(FEATURED, (item, index) => (
              <MemberBenefit {...item} index={index} key={index} />
            ))}
          </Container>
        </div>

        <Container>
          <Row className="my-5">
            <Col md={{ size: 8, offset: 2 }}>
              <SecurityAndTrust />
            </Col>
          </Row>
        </Container>

        <div
          className="py-5 border-top border-bottom border-dark shadow-inset bg-dark"
          style={{
            width: "100%",
            minHeight: "50vh",
          }}
        >
          <Container>
            <Row className="my-5">
              <Col>
                <div className="d-flex flex-column-reverse flex-md-row align-items-center justify-content-center">
                  <div className="d-none d-md-block p-4 bg-white rounded-left shadow w-100">
                    {existingLoginContent}
                  </div>
                  <div className="d-block d-md-none p-4 bg-white rounded shadow w-100">
                    {existingLoginContent}
                  </div>

                  <div className="mb-5 mb-md-0 p-4 p-md-5 rounded bg-white shadow w-100">
                    <h4>New Members</h4>
                    <p>For new members who need to create an account.</p>
                    <Button href={routes.account.signup} block color="primary">
                      Sign Up
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="w-100">
          <Container>
            <Row className="mt-5">
              <Col lg={{ size: 8, offset: 2 }}>
                <h5 className="text-center">Frequently Asked Questions</h5>
                <FrequentlyAskedQuestions />
              </Col>
            </Row>

            <Row className="my-5">
              <Col lg={{ size: 8, offset: 2 }}>
                <h5 className="text-center">Privacy Policy</h5>
                <p className="text-center">
                  We will not release information about you to any third party
                  without your written permission.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </Layout>
  );
}
