import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { SmallGroupType } from "../types";

const MERGE = { merge: true };

export default function useUpdateSmallGroup() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return async (group: string, updates: SmallGroupType) => {
    try {
      await db.doc(`groups/${group}`).set(updates, MERGE);
    } catch (error) {
      reportError(error.message, "useUpdateSmallGroup");
    }
  };
}
