import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import useAuth from "./useAuth";

export default function usePostToFeed() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  return async (message: string) => {
    if (user) {
      try {
        const doc = await db.collection("discussion").add({
          message,
          uid: user.uid,
          created: firebase.firestore.Timestamp.now(),
          author: user.displayName,
        });

        return doc.id;
      } catch (e) {
        reportError(e, "usePostToFeed");
      }
    }
    return Promise.resolve();
  };
}
