import React, { MouseEvent } from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import { isEmpty, map } from "lodash";
import {
  mdiChatOutline,
  mdiCheckCircle,
  mdiEmailOutline,
  mdiHomeRoof,
} from "@mdi/js";
import Icon from "@mdi/react";
import MainLayout from "./MainLayout";
import useNotifications from "../hooks/useNotifications";
import getCreatedTimestampFormatted from "../utils/getCreatedTimestampFormatted";
import { NotificationType } from "../types";
import useMarkNotificationRead from "../hooks/useMarkNotificationRead";
import useRouter from "../hooks/useRouter";
import If from "../components/helpers/If";

const ICONS = {
  discussion: mdiChatOutline,
  message: mdiEmailOutline,
  response: mdiChatOutline,
  resource: mdiHomeRoof,
};

const DESCRIPTIONS = {
  discussion: "There is a new discussion",
  message: "You've got a new private message",
  response: "You've got a new discussion response",
  resource: "You've got a new resource submission",
};

interface NotificationItemProps {
  notification: NotificationType;
}

function NotificationItem({ notification }: NotificationItemProps) {
  const { routeTo } = useRouter();
  const markNotificationRead = useMarkNotificationRead();

  const onView = async (e: MouseEvent) => {
    routeTo(notification.resourceUrl);
    await markNotificationRead(notification.id);
  };

  return (
    <ListGroupItem key={notification.path} tag="button" action onClick={onView}>
      <div className="d-flex align-items-center">
        <Icon path={ICONS[notification.kind]} size={1.5} />
        <div className="ml-2 d-flex flex-column">
          <div>{DESCRIPTIONS[notification.kind]}</div>
          <small className="text-muted">
            {getCreatedTimestampFormatted(notification)}
          </small>
        </div>
      </div>
    </ListGroupItem>
  );
}

export default function MyNotifications() {
  const { notifications } = useNotifications();

  return (
    <MainLayout>
      <div className="d-flex flex-column h-100">
        <h1 className="h4">Notifications</h1>

        <ListGroup>
          {map(notifications, (notification) => (
            <NotificationItem
              key={notification.path}
              notification={notification}
            />
          ))}
        </ListGroup>

        <If test={isEmpty(notifications)}>
          <div className="text-muted lead">
            Notifications will appear here when other users send you a private
            message or respond to one of your discussions.
          </div>

          <div className="d-flex flex-column align-items-center justify-content-center h-100 text-muted">
            <Icon path={mdiCheckCircle} size={4} className="text-muted" />
            <div>
              <em>You're all caught up!</em>
            </div>
          </div>
        </If>
      </div>
    </MainLayout>
  );
}
