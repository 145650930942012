import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import useAuth from "./useAuth";
import { RecommendationType } from "../types";

export default function usePostToRecommendation() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  return (recommendation: RecommendationType) => {
    if (user) {
      return db
        .collection("recommendation")
        .add({
          ...recommendation,
          uid: user.uid,
          created: firebase.firestore.Timestamp.now(),
        })
        .catch((e) => reportError(e, "usePostToRecommendation"));
    }
    return Promise.resolve();
  };
}
