import React, { useState } from "react";
import { ListGroup, ListGroupItem, Collapse } from "reactstrap";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";
import toggle from "../utils/toggle";
import Icon from "@mdi/react";
import { APP_NAME } from "./AppName";
import { flatten, map } from "lodash";

const DATA = [
  {
    question: `How does ${APP_NAME} help me find like-minded members near me?`,
    answer: `${APP_NAME} uses the general region you live in to help locate other members near you. Your region will be determined by the city/county and state you provide in your profile. This enables ${APP_NAME} to have a general idea of your region without storing any information about your exact location.`,
  },
  {
    question: `What is a MAG?`,
    answer: `A MAG, or mutual assistance group, is a group of individuals that live in the same geographic region and have agreed to offer help to one another in times of crisis.`,
  },
  {
    question: `Is there a charge for being a registered member of ${APP_NAME}?`,
    answer: `There is no charge for registering with ${APP_NAME}.`,
  },
  {
    question: `If it's free to me, how does ${APP_NAME} cover operating expenses?`,
    answer: `As a side-project and labor of love, ${APP_NAME} is currently self-funded. As ${APP_NAME} grows, revenue streams such as site-wide sponsorship will be explored. Invasive sources of revenue, such as targeted advertising and sale of member info to 3rd parties, will never be considered.`,
  },
  {
    question: `How does ${APP_NAME} protect my privacy?`,
    answer: `${APP_NAME} does not collect or store any personal identity information.`,
  },
  {
    question: "How can I cancel my account if I choose to do so?",
    answer: "Simply email the word “cancel” to support@mag.community.",
  },
  {
    question: `Who is the creator of ${APP_NAME}?`,
    answer: [
      `Hi there! My name is Caleb and as I've learned about being well-equipped to handle the calamities of living in this unpredictable and increasingly unstable world, I've realized that the single most important preparation is to have a trustworthy community of like-minded individuals.`,
      `In our increasingly online and socially isolated time, it can be hard to make those initial connections on which to build those relationships - especially if you've just moved. ${APP_NAME} is a labor of love born out of my own desire to grow those trustworthy relationships with others in my area.`,
      `I hope that you'll find ${APP_NAME} helpful as you do the same.`,
    ],
  },
];

interface Props {
  question: string;
  answer: string | string[];
}

function FrequentlyAskedQuestion({ question, answer }: Props) {
  const [isOpen, setOpen] = useState(false);
  const onToggle = () => setOpen(toggle);

  const icon = isOpen ? mdiChevronUp : mdiChevronDown;

  const paragraphs = flatten([answer]);

  return (
    <ListGroupItem tag="button" action onClick={onToggle}>
      <div className="d-flex align-items-start">
        <Icon path={icon} size={1} className="flex-shrink-0" />
        <strong>{question}</strong>
      </div>
      <Collapse isOpen={isOpen}>
        <div className="py-2">
          {map(paragraphs, (text, index) => (
            <p
              key={index}
              className={index === paragraphs.length - 1 ? "mb-0" : ""}
            >
              {text}
            </p>
          ))}
        </div>
      </Collapse>
    </ListGroupItem>
  );
}

export default function FrequentlyAskedQuestions() {
  return (
    <ListGroup>
      {DATA.map(({ question, answer }, index) => (
        <FrequentlyAskedQuestion
          key={index}
          question={question}
          answer={answer}
        />
      ))}
    </ListGroup>
  );
}
