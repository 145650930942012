import React, { FormEvent } from "react";
import { Button, Form, FormGroup, Input } from "reactstrap";
import useFormInputState from "../hooks/useFormInputState";

interface Props {
  onCancel: () => void;
  onDelete: () => void;
  confirmation: string;
}

export default function DeleteConfirmation(props: Props) {
  const [confirmation, onChangeConfirmation] = useFormInputState("");
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    props.onDelete();
  };

  const canDelete = confirmation === props.confirmation;

  return (
    <Form onSubmit={onSubmit}>
      <div className="text-danger border border-danger mb-3 p-2 rounded-sm">
        <strong>Deleting this record cannot be reversed.</strong>
      </div>
      <p>
        Please type <strong>{props.confirmation}</strong> to confirm.
      </p>
      <FormGroup>
        <Input
          type="text"
          onChange={onChangeConfirmation}
          value={confirmation}
        />
      </FormGroup>
      <div className="d-flex justify-content-center">
        <Button type="submit" color="danger" block disabled={!canDelete}>
          I understand the consequences, delete this record
        </Button>
      </div>
    </Form>
  );
}
