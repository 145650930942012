import React, { useEffect } from "react";
import { Button } from "reactstrap";

import * as firebase from "firebase/app";

import routes from "../../routes";
import Layout from "./Layout";
import useErrorProvider from "../../hooks/useErrorProvider";

export default () => {
  const { reportError } = useErrorProvider();

  useEffect(() => {
    firebase.auth().signOut().catch(reportError);
  }, [reportError]);

  return (
    <Layout.Container>
      <h3>Sign Out</h3>
      <Layout.Footer>
        <div>You have been signed out.</div>
        <Button color="primary" href={routes.account.signin}>
          Sign In
        </Button>
      </Layout.Footer>
    </Layout.Container>
  );
};
