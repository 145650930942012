import React, { Fragment, FormEvent, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
} from "reactstrap";
import { get } from "lodash";

import { SmallGroupType } from "../types";
import BusyButton from "./BusyButton";
import useFormInputState from "../hooks/useFormInputState";
import classes from "../utils/classes";
import Icon from "@mdi/react";
import { mdiEmailOutline, mdiRadioHandheld, mdiTelegram } from "@mdi/js";

type OnUpdate = (updatedSmallGroup: SmallGroupType) => Promise<void>;

interface Props {
  readonly: boolean;
  smallGroup?: SmallGroupType | null | {};
  onUpdate: OnUpdate;
  canPreview?: boolean;
  updateLabel?: string;
}

const DEFAULT_NAME = "";
const DEFAULT_CONTACT = "";
const DEFAULT_DESCRIPTION = "";
const DEFAULT_CITY = "";
const DEFAULT_STATE = "";
const DEFAULT_ZIP_CODE = "";

export default function SmallGroupForm({
  readonly = true,
  smallGroup: initialSmallGroup = {},
  onUpdate,
  canPreview = true,
  updateLabel = "Update",
}: Props) {
  const [isUpdating, setUpdating] = useState(false);

  const [name, onChangeName] = useFormInputState(
    get(initialSmallGroup, "name", DEFAULT_NAME)
  );
  const [contact, onChangeContact] = useFormInputState(
    get(initialSmallGroup, "contact", DEFAULT_CONTACT)
  );
  const [description, onChangeDescription] = useFormInputState(
    get(initialSmallGroup, "description", DEFAULT_DESCRIPTION)
  );
  const [email, onChangeEmail] = useFormInputState(
    get(initialSmallGroup, "email", "")
  );
  const [radio, onChangeRadio] = useFormInputState(
    get(initialSmallGroup, "radio", "")
  );
  const [telegram, onChangeTelegram] = useFormInputState(
    get(initialSmallGroup, "telegram", "")
  );
  const [city, onChangeCity] = useFormInputState(
    get(initialSmallGroup, "city", DEFAULT_CITY)
  );
  const [state, onChangeState] = useFormInputState(
    get(initialSmallGroup, "state", DEFAULT_STATE)
  );
  const [zipCode, onChangeZipCode] = useFormInputState(
    get(initialSmallGroup, "zipCode", DEFAULT_ZIP_CODE)
  );

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    setUpdating(true);

    await onUpdate({
      name,
      description,
      email,
      radio,
      telegram,
      city,
      state,
      zipCode,
    });

    setUpdating(false);
  };

  return (
    <Fragment>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label for="name">Group name</Label>
          <Input
            type="text"
            placeholder="Please enter the group's name"
            readOnly={readonly}
            value={name}
            onChange={onChangeName}
          />
        </FormGroup>

        <FormGroup className="mb-2">
          <Label for="name">Group city / county</Label>
          <Input
            type="text"
            placeholder="City or county"
            readOnly={readonly}
            value={city}
            onChange={onChangeCity}
          />
        </FormGroup>

        <FormGroup row>
          <Col size={8}>
            <Input
              type="text"
              placeholder="State"
              readOnly={readonly}
              value={state}
              onChange={onChangeState}
            />
          </Col>
          <Col size={4}>
            <Input
              type="text"
              placeholder="Zip Code"
              readOnly={readonly}
              value={zipCode}
              onChange={onChangeZipCode}
            />
          </Col>
        </FormGroup>

        <FormGroup>
          <Label for="email">Group contact information</Label>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon path={mdiEmailOutline} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="email"
              placeholder="Group email"
              readOnly={readonly}
              value={email}
              onChange={onChangeEmail}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon path={mdiRadioHandheld} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Radio contact (frequency)..."
              readOnly={readonly}
              value={radio}
              onChange={onChangeRadio}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Icon path={mdiTelegram} size={0.7} />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              placeholder="Group Telegram username..."
              readOnly={readonly}
              value={telegram}
              onChange={onChangeTelegram}
            />
          </InputGroup>
        </FormGroup>

        <FormGroup>
          <Label for="name">Group description</Label>
          <Input
            type="textarea"
            rows={10}
            placeholder="Please share a little about the group..."
            readOnly={readonly}
            value={description}
            onChange={onChangeDescription}
            style={{ minHeight: 200 }}
          />
        </FormGroup>

        <FormGroup>
          <Label for="name">Your name</Label>
          <Input
            type="text"
            placeholder="Please enter contact name"
            readOnly={readonly}
            value={contact}
            onChange={onChangeContact}
          />
          <FormText>The group leader/organizer's name</FormText>
        </FormGroup>

        <div className="d-flex">
          <BusyButton
            color="success"
            className={classes({ "ml-auto": !canPreview }, "shadow")}
            busyLabel="Updating"
            isBusy={isUpdating}
          >
            {updateLabel}
          </BusyButton>
        </div>
      </Form>
    </Fragment>
  );
}
