import React from "react";
import { isEmpty, get } from "lodash";

import MainLayout from "./MainLayout";
import BiographyForm from "../components/BiographyForm";
import useUpdateProfile from "../hooks/useUpdateProfile";
import { ProfileType } from "../types";
import useAccountProfile from "../hooks/useAccountProfile";
import If from "../components/helpers/If";
import { Spinner } from "reactstrap";
import useUploadAvatar from "../hooks/useUploadAvatar";
import formatAddress from "../utils/formatAddress";
import useGeocoder from "../hooks/useGeocoder";
import ProfileNavigation from "../components/ProfileNavigation";

export default function MyProfile() {
  const { profile } = useAccountProfile();
  const lookup = useGeocoder();
  const updateProfile = useUpdateProfile();
  const uploadAvatar = useUploadAvatar();

  const onUpdate = async (
    updatedProfile: ProfileType,
    avatarFile: File | undefined
  ) => {
    const address = formatAddress(updatedProfile);
    const geocodeResult = await lookup(address);
    const location = get(geocodeResult, "center", null);

    const avatarFilename = avatarFile
      ? await uploadAvatar(avatarFile)
      : profile.avatarFilename;
    console.log({
      updatedProfile,
      address,
      geocodeResult,
      location,
      avatarFile,
      avatarFilename,
    });

    await updateProfile({ ...updatedProfile, location, avatarFilename });
  };

  return (
    <MainLayout>
      <h1 className="h4">Your Profile</h1>

      <ProfileNavigation />

      <If test={!isEmpty(profile)}>
        <BiographyForm profile={profile} onUpdate={onUpdate} readonly={false} />
      </If>

      <If test={isEmpty(profile)}>
        <div className="text-center" style={{ margin: "200px 0px" }}>
          <Spinner color="primary" size="md" />
        </div>
      </If>
    </MainLayout>
  );
}
