import React, { useMemo } from "react";
import { get } from "lodash";

import { ProfileType } from "../types";
import AvatarDisplay from "./AvatarDisplay";
import { Button } from "reactstrap";
import useAuth from "../hooks/useAuth";
import If from "./helpers/If";
import useStartConversation from "../hooks/useStartConversation";

interface Props {
  username: string;
  profile: ProfileType;
}

export default function ProfileHeader({ username, profile }: Props) {
  const { user } = useAuth();
  const participants = useMemo(
    () => [user?.uid, profile?.uid],
    [user, profile]
  );
  const startConversation = useStartConversation();

  const onMessage = async () => {
    await startConversation(participants);
  };
  return (
    <div className="d-flex align-items-center">
      <AvatarDisplay
        avatarFilename={get(profile, "avatarFilename")}
        username={profile.name ?? profile.id}
      />
      <div className="ml-3">
        <h1 className="h5 mb-0">{profile.name}</h1>
        <small className="text-muted">{username}</small>
        <br />
      </div>
      <If test={user && user.displayName !== username}>
        <div className="ml-auto">
          <Button color="success" outline onClick={onMessage}>
            Message
          </Button>
        </div>
      </If>
    </div>
  );
}
