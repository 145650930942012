import React, { createContext, useContext, useState } from "react";
import { Alert, Fade } from "reactstrap";
import { get } from "lodash";

const NotificationContext = createContext();

const NOTIFICATION_TIMEOUT = 5000;

export const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState();
  const [isOpen, setOpen] = useState(false);

  const notify = (theNotification, level = "secondary") => {
    setNotification({
      message: get(theNotification, "message", theNotification),
      level,
    });
    setOpen(true);

    setTimeout(() => setOpen(false), NOTIFICATION_TIMEOUT);
  };

  const contextValue = {
    notify,
  };

  const onDismiss = () => setOpen(false);

  const { message, level } = notification || {};

  return (
    <NotificationContext.Provider value={contextValue}>
      <Fade
        in={isOpen}
        tag="div"
        className="toast-container"
        mountOnEnter
        unmountOnExit
      >
        <div className="shadow-lg rounded">
          <Alert
            color={level}
            className={`border border-${level}`}
            toggle={onDismiss}
          >
            {message}
          </Alert>
        </div>
      </Fade>
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotification = () => useContext(NotificationContext);
