import React from "react";
import { Button, Spinner } from "reactstrap";
import { groupBy, map, sortBy, toUpper } from "lodash";
import useAllSmallGroups from "../hooks/useAllSmallGroups";
import MainLayout from "./MainLayout";
import If from "../components/helpers/If";
import SmallGroup from "../components/SmallGroup";
import routes from "../routes";
import isSupportAccount from "../utils/isSupportAccount";
import useSupportProfile from "../hooks/useSupportProfile";
import useAuth from "../hooks/useAuth";
import { SmallGroupType } from "../types";

export default function SmallGroups() {
  const { user } = useAuth();
  const supportProfile = useSupportProfile();
  const { groups, isLoading } = useAllSmallGroups();

  const isGroupVisible = (group: SmallGroupType) =>
    group.approved ||
    isSupportAccount(supportProfile) ||
    (user && group.uid === user.uid);

  const visibleGroups = groups.filter(isGroupVisible);
  const groupsByState = groupBy(visibleGroups, (group) => toUpper(group.state));
  const sortedGroupsByState = sortBy(
    map(groupsByState, (groupsInState, state) => ({ state, groupsInState })),
    "state"
  );

  return (
    <MainLayout>
      <div className="d-flex flex-column h-100">
        <div className="d-flex justify-content-between align-items-start">
          <h1 className="h4 mb-0">Mutual Assistance Groups</h1>
          <Button color="dark" size="md" href={routes.groups.create}>
            Create
          </Button>
        </div>

        <div className="mb-3 text-muted lead">
          Groups of like-minded and geographically co-located individuals who
          come together for mutual assistance in times of crisis.
        </div>

        {map(sortedGroupsByState, ({ groupsInState, state }) => (
          <div key={state} className="mb-3">
            <h6>{state}</h6>
            {map(groupsInState, (smallGroup) => (
              <SmallGroup key={smallGroup.id} smallGroup={smallGroup} />
            ))}
          </div>
        ))}

        <If test={isLoading}>
          <div className="text-center" style={{ margin: "200px 0px" }}>
            <Spinner color="primary" size="md" />
          </div>
        </If>
      </div>
    </MainLayout>
  );
}
