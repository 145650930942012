import React from "react";
import { Button } from "reactstrap";
import routes from "../routes";
import { APP_NAME } from "./AppName";

export default function Footer() {
  return (
    <div className="py-5">
      <div className="text-center">
        <div>
          <Button outline color="dark" href={routes.support.email}>
            Contact Support
          </Button>
        </div>
      </div>
      <footer className="pt-3 text-center">
        <div>
          <small>Copyright {APP_NAME}. All rights reserved.</small>
        </div>
      </footer>
    </div>
  );
}
