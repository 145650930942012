import React, { FormEvent, ChangeEvent, useState } from "react";
import { Input, Form, Button } from "reactstrap";
import { isEmpty, trim } from "lodash";

import useErrorProvider from "../hooks/useErrorProvider";
import BusyButton from "./BusyButton";
import useUpdateDirectMessage from "../hooks/useUpdateDirectMessage";
import { DirectMessageType } from "../types";

interface Props {
  conversation: string;
  message: DirectMessageType;
  onUpdated: () => void;
  onCancel: () => void;
}

export default function EditDirectMessageForm({
  conversation,
  message,
  onUpdated,
  onCancel,
}: Props) {
  const [isPosting, setPosting] = useState(false);
  const { reportError } = useErrorProvider();
  const [text, setMessage] = useState(message.text);

  const canPost = !isEmpty(trim(text));

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) =>
    setMessage(e.target.value);

  const updateDirectMessage = useUpdateDirectMessage();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (message.id) {
      try {
        setPosting(true);
        await updateDirectMessage(conversation, message.id, { text });

        onUpdated();
      } catch (e) {
        reportError(`Failed to update your message. Please try again later.`);
      } finally {
        setPosting(false);
      }
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        type="textarea"
        autoFocus
        placeholder={message.text}
        rows={10}
        value={text}
        onChange={onChangeText}
      />
      <div className="mt-3 d-flex align-items-center justify-content-end">
        <div>
          <BusyButton
            isBusy={isPosting}
            color="success"
            type="submit"
            className="shadow"
            disabled={!canPost}
          >
            Update
          </BusyButton>
          <Button color="dark" outline onClick={onCancel} className="ml-2">
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
