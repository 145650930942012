type RecordWithOwnerType = {
  uid?: string | undefined;
};

export default function canModify(
  user: firebase.User | null | undefined,
  record?: RecordWithOwnerType | null | undefined
) {
  return user && record ? user.uid === record.uid : false;
}
