import React, { createContext, useContext, useState } from "react";
import { Alert, Fade } from "reactstrap";
import { get } from "lodash";

import routes from "../routes";

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState();
  const [isOpen, setOpen] = useState(false);

  const reportError = (anError, callsite = "unknown") => {
    console.error(`[${callsite}]`, anError);
    setError(get(anError, "message", anError));
    setOpen(true);
  };

  const contextValue = {
    reportError,
  };

  const onDismiss = () => setOpen(false);

  return (
    <ErrorContext.Provider value={contextValue}>
      <Fade
        in={isOpen}
        tag="div"
        className="toast-container"
        mountOnEnter
        unmountOnExit
      >
        <div className="shadow-lg rounded">
          <Alert color="danger" toggle={onDismiss}>
            {error}
            <div className="mt-3">
              If this problem persists, please{" "}
              <a href={routes.support.email}>contact support</a>.
            </div>
          </Alert>
        </div>
      </Fade>
      {children}
    </ErrorContext.Provider>
  );
};

const useErrorProvider = () => useContext(ErrorContext);

export default useErrorProvider;
