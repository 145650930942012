import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";

export default function useUploadAvatar() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const storage = firebase.storage();

  return async (avatarFile: File) => {
    if (user) {
      const filename = `avatar/${user.uid}/avatar`;

      try {
        const fileResult = await storage.ref().child(filename).put(avatarFile);

        const metadataResult = await storage
          .ref()
          .child(filename)
          .updateMetadata({ customMetadata: { uid: user.uid } });

        console.log(fileResult, metadataResult);

        return filename;
      } catch (e) {
        reportError(e.message, "useUploadAvatar");
      }
    }
    return undefined;
  };
}
