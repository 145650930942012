import * as firebase from "firebase/app";
import { Maybe } from "../types";
import useErrorProvider from "./useErrorProvider";

export default function useMarkNotificationRead() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  return async (notificationId: Maybe<string>) => {
    console.log(`Marking notification as read (deleting) ${notificationId}`);
    try {
      if (notificationId) {
        await db.collection("notifications").doc(notificationId).delete();
      }
    } catch (e) {
      reportError(e, "useMarkNotificationRead");
    }
  };
}
