import React from "react";
import MainLayout from "./MainLayout";
import ConversationsList from "../components/DirectMessagesList";
import { DESCRIPTIONS } from "../components/SecondaryNavigation";

export default function ConversationsPage() {
  return (
    <MainLayout>
      <div className="d-flex flex-column h-100">
        <h1 className="h4 mb-0">Private Messages</h1>
        <div className="mb-3 text-muted lead">{DESCRIPTIONS.conversations}</div>
        <ConversationsList hasBorder={false} hasTitle={false} />
      </div>
    </MainLayout>
  );
}
