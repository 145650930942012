import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import * as firebase from "firebase/app";

import useErrorProvider from "./useErrorProvider";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import { ConversationType } from "../types";
import useAuth from "./useAuth";

type ContextProps = {
  conversations: Array<ConversationType>;
  isLoading: boolean;
};

const defaultContextValue = {
  conversations: [],
  isLoading: false,
};

const Context = createContext<ContextProps>(defaultContextValue);
const { Provider } = Context;

interface Props {
  children: React.ReactNode;
}

export function ConversationsProvider({ children }: Props) {
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (user) {
      const db = firebase.firestore();
      setLoading(true);
      return db
        .collection(`conversations`)
        .where("participants", "array-contains", user.uid)
        .onSnapshot({
          next(result) {
            setLoading(false);
            setConversations(getDataFromDocuments(result.docs));
          },
          error: (e) => {
            reportError(e, "useConversationsForUser");
          },
        });
    }
  }, [reportError, user]);

  const contextValue = useMemo(
    () => ({ conversations, isLoading }),
    [conversations, isLoading]
  );

  return <Provider value={contextValue}>{children}</Provider>;
}

export default function useConversationsForUser() {
  return useContext(Context);
}
