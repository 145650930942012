type HookReturnValue = {
  getItem<T>(key: string, defaultValue?: T): T;
  setItem<T>(key: string, value: T): void;
};

const getItem = <T>(key: string, defaultValue?: T) => {
  const rawItem = localStorage.getItem(key);
  try {
    return rawItem !== null ? JSON.parse(rawItem) : defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

const setItem = <T>(key: string, value: T) => {
  if (process.env.NODE_ENV === "development") {
    console.log(`setItem(${key}, ${value})`);
  }
  localStorage.setItem(key, JSON.stringify(value));
};

const RETURN_VALUE = { getItem, setItem };

export default function useLocalStorage(): HookReturnValue {
  return RETURN_VALUE;
}
