import { isEmpty } from "lodash";

const ROOT_URL = process.env.REACT_APP_PUBLIC_URL || "";

console.log(`Using root: "${ROOT_URL}"`);

export default {
  landing: `${ROOT_URL}/`,

  intel: `${ROOT_URL}/intel`,

  dashboard: `${ROOT_URL}/dashboard`,
  home: `${ROOT_URL}/home`,
  discover: `${ROOT_URL}/discover`,

  allDiscussions: `${ROOT_URL}/discussions`,
  discussion: (id: string = ":id") => `${ROOT_URL}/discussions/${id}`,
  discussionResponse: (id: string, responseId: string) =>
    `${ROOT_URL}/discussions/${id}#${responseId}`,
  allRecommendations: `${ROOT_URL}/recommendations`,

  myProfile: `${ROOT_URL}/my/profile`,
  myFeed: `${ROOT_URL}/my/discussions`,
  myRecommendations: `${ROOT_URL}/my/recommendations`,
  myNotifications: `${ROOT_URL}/my/notifications`,

  user: (username: string = ":username") => `${ROOT_URL}/u/${username}`,
  feed: (username: string = ":username") =>
    `${ROOT_URL}/u/${username}/discussions`,
  recommendations: (username: string = ":username") =>
    `${ROOT_URL}/u/${username}/recommendations`,

  conversations: `${ROOT_URL}/messages`,
  conversation: (conversation: string = ":conversation") =>
    `${ROOT_URL}/messages/${conversation}`,

  resources: `${ROOT_URL}/resources`,

  groups: {
    list: `${ROOT_URL}/groups`,
    create: `${ROOT_URL}/groups/create`,
    view: (group: string = ":group") => `${ROOT_URL}/groups/${group}`,
    edit: (group: string = ":group") => `${ROOT_URL}/groups/${group}/edit`,
  },

  events: {
    landing: `${ROOT_URL}/events`,
  },

  styleguide: `${ROOT_URL}/styleguide`,

  account: {
    signup: `${ROOT_URL}/account/sign-up`,
    signin: `${ROOT_URL}/account/sign-in`,
    signinWithRedirect: (to = ``) =>
      `${ROOT_URL}/account/sign-in?redirect=${encodeURIComponent(to)}`,
    signout: `${ROOT_URL}/account/sign-out`,
    recover: `${ROOT_URL}/account/recovery`,
    reset: `${ROOT_URL}/account/reset-password`,
    welcome: `${ROOT_URL}/account/welcome`,
    loading: `${ROOT_URL}/account/loading`,
    loadingWithRedirect: (to = ``) => {
      return isEmpty(to)
        ? `${ROOT_URL}/account/loading`
        : `${ROOT_URL}/account/loading?redirect=${to}`;
    },
    action: `${ROOT_URL}/account/action`,
    token: `${ROOT_URL}/account/token`,
  },

  support: {
    dashboard: `${ROOT_URL}/support/dashboard`,
    analysis: `${ROOT_URL}/support/analysis`,
    accounts: `${ROOT_URL}/support/accounts`,
    courses: `${ROOT_URL}/support/courses`,
    email: `mailto:mag.community@protonmail.com`,
  },

  redirect: (destination = `:destination`) =>
    `${ROOT_URL}/redirect/${destination}`,
};
