import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

const MERGE = { merge: true };

export default function useMarkusernameUnavailable() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return (user: firebase.User) => {
    if (user && user.displayName) {
      return db
        .collection("username")
        .doc(user.displayName)
        .set(
          {
            updated: firebase.firestore.Timestamp.now(),
            uid: user.uid,
          },
          MERGE
        )
        .catch((error) => {
          console.error(error);
          reportError(error.message, "useMarkusernameUnavailable");
        });
    }
    return Promise.resolve();
  };
}
