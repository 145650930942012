import React from "react";
import { Container, Row, Col } from "reactstrap";

import Rating from "../../components/Rating";
import DateTime from "../../components/DateTime";
import DateInput from "../../components/Date";
import BusyButton from "../../components/BusyButton";
import BusyMessage from "../../components/BusyMessage";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import BiographyForm from "../../components/BiographyForm";
import Sidebar from "../../components/Sidebar";
import AvatarPreview from "../../components/AvatarPreview";
import MapDisplay from "../../components/MapDisplay";
import AddressPreview from "../../components/AddressPreview";
import NewFeedItemForm from "../../components/NewFeedItemForm";
import NewRecommendationForm from "../../components/NewRecommendationForm";
import FeedItem from "../../components/FeedItem";
import Recommendation from "../../components/Recommendation";
import ProfileNavigation from "../../components/ProfileNavigation";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions";
import DirectMessage from "../../components/DirectMessage";
import DirectMessages from "../../components/DirectMessages";

const components = [
  {
    Component: FeedItem,
    props: {
      item: {
        content: "Hello, feed world!",
        created: null,
      },
    },
  },

  {
    Component: NewFeedItemForm,
    props: {},
  },

  {
    Component: DirectMessages,
    props: {
      conversation: "me-them",
      currentUid: "me",
      messages: [
        {
          text: "Hello, world!",
          created: Date.now(),
          sender: "me",
          receiver: "them",
        },
        {
          text: "Back at you!",
          created: Date.now(),
          sender: "them",
          receiver: "me",
        },
        {
          text: "How are you?",
          created: Date.now(),
          sender: "me",
          receiver: "them",
        },
        {
          text: "Good!",
          created: Date.now(),
          sender: "them",
          receiver: "me",
        },
        {
          text: "You?",
          created: Date.now(),
          sender: "them",
          receiver: "me",
        },
        {
          text: "I'm great, thanks!",
          created: Date.now(),
          sender: "me",
          receiver: "them",
        },
      ],
    },
    containerStyle: {
      height: 400,
      minHeight: 400,
      maxHeight: 400,
    },
  },

  {
    Component: DirectMessage,
    props: {
      message: {
        text: "Hello, world!",
        created: Date.now(),
      },
      alignment: "right",
    },
  },
  {
    Component: DirectMessage,
    props: {
      message: {
        text: "Hello, world!",
        created: Date.now(),
      },
      alignment: "left",
    },
  },

  {
    Component: Recommendation,
    props: {
      recommendation: {
        title: "A Wonderful Resource",
        href: "http://example.com",
        content: "This is great.\nI mean, it's really great!",
        created: null,
      },
    },
  },

  {
    Component: NewRecommendationForm,
    props: {},
  },

  {
    Component: FrequentlyAskedQuestions,
    props: {},
  },

  {
    Component: ProfileNavigation,
    props: {
      profileHref: "/u/saint-francis",
      feedHref: "/u/saint-francis/feed",
    },
  },

  {
    Component: AddressPreview,
    props: {},
  },

  {
    Component: MapDisplay,
    props: { center: [51.505, -0.09] },
  },

  {
    Component: AvatarPreview,
    props: { avatarUrl: "https://cubicle6.com/img/portrait-3.jpg" },
  },
  {
    Component: AvatarPreview,
    props: {},
  },

  {
    Component: Sidebar,
    props: {},
  },

  {
    Component: BiographyForm,
    props: { readonly: false },
  },

  {
    Component: BiographyForm,
    props: { readonly: true },
  },

  {
    Component: DeleteConfirmation,
    props: {
      confirmation: "Jane Austin",
    },
  },

  {
    Component: BusyMessage,
    props: {
      children: "Busy working...",
    },
  },

  {
    Component: BusyButton,
    props: { color: "success", children: "Save", isBusy: false },
  },
  {
    Component: BusyButton,
    props: { color: "success", busyLabel: "Loading", isBusy: true },
  },
  {
    Component: BusyButton,
    props: { color: "success", isBusy: true },
  },

  {
    Component: DateInput,
    props: {
      value: Date.now(),
      onChange: console.log,
    },
  },
  {
    Component: DateInput,
    props: {
      value: null,
      onChange: console.log,
    },
  },
  {
    Component: DateInput,
    props: {
      invalid: true,
      value: null,
      onChange: console.log,
    },
  },

  {
    Component: DateTime,
    props: {
      value: Date.now(),
      onChange: console.log,
    },
  },
  {
    Component: DateTime,
    props: {
      value: null,
      onChange: console.log,
    },
  },
  {
    Component: DateTime,
    props: {
      invalid: true,
      value: null,
      onChange: console.log,
    },
  },

  {
    Component: Rating,
    props: {
      value: 3,
      onChange(value) {
        alert(value);
      },
    },
  },
  { Component: Rating, props: { value: 3 } },
];

class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return null;
    }
    return this.props.children;
  }
}

export default () => (
  <Container className="py-5">
    <Row className="mt-5">
      <Col>
        <h1 className="text-center">Styleguide</h1>
      </Col>
    </Row>
    <Row>
      <Col>
        {components.map(
          ({ Component, props = {}, containerStyle = {} }, index) => (
            <div
              className="mb-5 border-bottom border-light rounded p-3 bg-white"
              key={index}
            >
              <code className="d-block mb-3">{Component.name}</code>
              <div style={containerStyle}>
                <ErrorBoundary>
                  <Component {...props} />
                </ErrorBoundary>
              </div>
            </div>
          )
        )}
      </Col>
    </Row>
  </Container>
);
