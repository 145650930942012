import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocument from "../utils/getDataFromDocument";
import { Maybe, SmallGroupType } from "../types";

type MaybeSmallGroupType = Maybe<SmallGroupType>;

type HookReturnValue = {
  smallGroup: MaybeSmallGroupType;
  isLoading: boolean;
};

export default function useSmallGroup(group: string): HookReturnValue {
  const [isLoading, setLoading] = useState(false);
  const [record, setRecord] = useState<MaybeSmallGroupType>(undefined);
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (group) {
      setLoading(true);
      const db = firebase.firestore();
      db.collection("groups")
        .doc(group)
        .get()
        .then((doc) => {
          setRecord(getDataFromDocument(doc));
        })
        .catch((e) => reportError(e, "useSmallGroup"))
        .finally(() => setLoading(false));
    }
  }, [reportError, group]);

  return { smallGroup: record, isLoading };
}
