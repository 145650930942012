import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import { DirectMessageType } from "../types";

type HookReturnValue = {
  messages: Array<DirectMessageType>;
  isLoading: boolean;
};

export default function useDirectMessages(
  conversationId: string
): HookReturnValue {
  const [isLoading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const { reportError } = useErrorProvider();

  const path = `conversations/${conversationId}/messages`;

  useEffect(() => {
    const db = firebase.firestore();

    let unsubscribe = () => {};

    async function run() {
      //await createConversationDocument(db, conversationId);

      setLoading(true);

      unsubscribe = db
        .collection(path)
        .orderBy("created", "asc")
        .onSnapshot({
          next(result) {
            setLoading(false);
            setMessages(getDataFromDocuments(result.docs));
          },
          error: (e) => {
            reportError(e, "useDirectMessages");
          },
        });
    }

    run();

    return () => unsubscribe();
  }, [conversationId, reportError, path]);

  return { messages, isLoading };
}
