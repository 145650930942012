import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import useAuth from "./useAuth";
import { NotificationKind } from "../types";
import { isUndefined } from "lodash";

function generateDocumentId(resourceUrl: string, recipient: string) {
  const resourceSlug = resourceUrl.replace(/\//g, "-");
  return `${recipient}-${resourceSlug}`;
}

export default function usePostNotifications() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  return async (
    resourceUrl: string,
    recipientUids: Array<string | undefined>,
    kind: NotificationKind
  ) => {
    if (user) {
      try {
        const transaction = db.batch();

        recipientUids.forEach((recipientUid) => {
          if (!isUndefined(recipientUid)) {
            const docId = generateDocumentId(resourceUrl, recipientUid);
            const docRef = db.doc(`notifications/${docId}`);

            transaction.set(docRef, {
              uid: recipientUid,
              kind,
              created: firebase.firestore.Timestamp.now(),
              resourceUrl,
            });
          }
        });

        await transaction.commit();
      } catch (e) {
        reportError(e, "usePostNotifications");
      }
    }
    return;
  };
}
