import { useState, useEffect } from "react";

import { ConversationType } from "../types";
import useErrorProvider from "./useErrorProvider";
import useGetConversation from "./useGetConversation";

type HookReturnValue = ConversationType;

const EMPTY = { participants: [] };

export default function useConversation(conversation: string): HookReturnValue {
  const [value, setValue] = useState<ConversationType>(EMPTY);
  const { reportError } = useErrorProvider();
  const getConversation = useGetConversation();

  useEffect(() => {
    getConversation(conversation)
      .then((theValue) => setValue(theValue || EMPTY))
      .catch((e) => reportError(e, "useConversation"));
  }, [conversation, reportError, getConversation]);

  return value;
}
