import React, { Fragment, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { RecommendationType } from "../types";
import getCreatedTimestampFormatted from "../utils/getCreatedTimestampFormatted";
import Formatted from "./Formatted";
import RecommendationMenu from "./RecommendationMenu";
import If from "./helpers/If";
import useDeleteRecommendation from "../hooks/useDeleteRecommendation";
import toggle from "../utils/toggle";
import EditRecommendationForm from "./EditRecommendationForm";
import { truncate } from "lodash";
import routes from "../routes";

interface Props {
  username: string;
  recommendation: RecommendationType;
  canEdit?: boolean;
}

export default function Recommendation({
  username,
  recommendation,
  canEdit,
}: Props) {
  const deleteRecommendation = useDeleteRecommendation();

  const onDelete = async () => {
    if (recommendation.id) {
      await deleteRecommendation(recommendation.id);
    }
  };

  const [isUpdating, setUpdating] = useState(false);
  const onToggleUpdate = () => setUpdating(toggle);
  const onEdit = () => {
    setUpdating(true);
  };
  const onUpdated = () => setUpdating(false);
  const onCancelUpdate = () => setUpdating(false);

  const formattedHref = truncate(recommendation.href, {
    separator: "/",
    length: 40,
  });

  return (
    <Fragment>
      <div className="mb-3 p-3 position-relative">
        <div className="d-flex justify-content-between align-items-center">
          <div className="text-muted">
            <small>
              Shared by{" "}
              <a href={routes.user(recommendation.author)}>
                {recommendation.author}
              </a>
            </small>
            <small>{getCreatedTimestampFormatted(recommendation)}</small>
          </div>
          <If test={canEdit}>
            <RecommendationMenu onDelete={onDelete} onEdit={onEdit} />
          </If>
        </div>

        <div>
          <a
            href={recommendation.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>{recommendation.title}</strong>
          </a>
          <br />
          <small className="text-muted">{formattedHref}</small>
        </div>

        <Formatted>{recommendation.content}</Formatted>
      </div>

      <Modal isOpen={isUpdating} toggle={onToggleUpdate} size="lg">
        <ModalHeader toggle={onToggleUpdate}>Edit Recommendation</ModalHeader>
        <ModalBody>
          <EditRecommendationForm
            username={username}
            recommendation={recommendation}
            onUpdated={onUpdated}
            onCancel={onCancelUpdate}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
