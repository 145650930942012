import React from "react";

export default function NotFound() {
  return (
    <div className="min-vh-100 d-flex align-items-center justify-content-center">
      <div className="p-5 bg-white shadow-lg rounded">
        <h1>Not Found</h1>
        <p>
          There doesn't appear to be anything at&nbsp;
          <code>{window.location.pathname}</code>
        </p>
      </div>
    </div>
  );
}
