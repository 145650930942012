import React from "react";
import MainLayout from "./MainLayout";
import useAllDiscussions from "../hooks/useAllDiscussions";
import { map } from "lodash";
import FeedItem from "../components/FeedItem";
import If from "../components/helpers/If";
import { Spinner } from "reactstrap";
import NewFeedItemForm from "../components/NewFeedItemForm";
import { DESCRIPTIONS } from "../components/SecondaryNavigation";

export default function DiscussionsPage() {
  const { discussions, isLoading } = useAllDiscussions();
  return (
    <MainLayout>
      <div className="d-flex flex-column h-100">
        <h1 className="h4 mb-0">Discussions</h1>
        <div>
          <small>{DESCRIPTIONS.discussions}</small>
        </div>

        <div className="my-3 rounded shadow bg-white p-3">
          <h2 className="h5">Start A New Discussion</h2>
          <NewFeedItemForm />
        </div>

        {map(discussions, (discussion) => (
          <FeedItem
            key={discussion.id}
            item={discussion}
            canEdit={false}
            canOpen={true}
            canTruncate={true}
          />
        ))}

        <If test={isLoading}>
          <div className="text-center" style={{ margin: "200px 0px" }}>
            <Spinner color="primary" size="md" />
          </div>
        </If>
      </div>
    </MainLayout>
  );
}
