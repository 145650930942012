import React, { useState, Fragment } from "react";
import {
  Container,
  Navbar,
  NavbarBrand,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import Icon from "@mdi/react";
import {
  mdiAccountCircle,
  mdiAccountMultiple,
  mdiLogoutVariant,
  mdiPaletteSwatch,
  mdiMenu,
  mdiMenuDown,
} from "@mdi/js";

import routes from "../routes";
import useAuth from "../hooks/useAuth";
import IfSupport from "./helpers/IfSupport";
import IfDevelopment from "./helpers/IfDevelopment";
import Logo from "./Logo";
import AppName from "./AppName";

interface NavProps {
  user: any;
}

const LoggedInNav = ({ user }: NavProps) => {
  const [isNavOpen, setNavOpen] = useState(false);
  const onToggleNav = () => setNavOpen(!isNavOpen);

  return (
    <div className="border-bottom border-light d-md-none">
      <Navbar expand color="white">
        <Container fluid>
          <NavbarBrand href={routes.discover} className="pl-1 mr-auto">
            <div className="d-flex align-items-center">
              <Logo size={0.7} />
              <div className="ml-1">
                <AppName />
              </div>
            </div>
          </NavbarBrand>

          <Dropdown isOpen={isNavOpen} toggle={onToggleNav} size="sm">
            <DropdownToggle color="light">
              <span className="d-none d-md-inline">{user.email}</span>
              <Icon className="d-inline d-md-none" path={mdiMenu} size={1} />
              <Icon
                className="d-none d-md-inline ml-md-2"
                path={mdiMenuDown}
                size={0.7}
              />
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem tag="a" href={routes.myProfile}>
                <Icon path={mdiAccountCircle} size={1} /> Profile
              </DropdownItem>
              <IfSupport>
                <DropdownItem divider />
                <DropdownItem tag="a" href={routes.support.accounts}>
                  <Icon path={mdiAccountMultiple} size={1} /> Manage Accounts
                </DropdownItem>
              </IfSupport>
              <IfDevelopment>
                <DropdownItem divider />
                <DropdownItem tag="a" href={routes.styleguide}>
                  <Icon path={mdiPaletteSwatch} size={1} /> Styleguide
                </DropdownItem>
              </IfDevelopment>
              <DropdownItem divider />
              <DropdownItem tag="a" href={routes.account.signout}>
                <Icon path={mdiLogoutVariant} size={1} /> Sign Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Container>
      </Navbar>
    </div>
  );
};

const LoggedOutNav = () => (
  <Fragment>
    <div className="fixed-top border-bottom border-light">
      <Navbar expand color="white">
        <Container>
          <NavbarBrand href={routes.landing} className="ml-3 pl-1 mr-3">
            <div className="d-flex align-items-center">
              <Logo size={0.7} />
              <div className="ml-1">
                <AppName />
              </div>
            </div>
          </NavbarBrand>

          <Nav navbar className="mr-auto">
            <NavItem>
              <NavLink href={routes.intel}>Intel</NavLink>
            </NavItem>
          </Nav>

          <Button
            color="link"
            className="d-none d-md-block"
            href={routes.account.signin}
          >
            Sign In
          </Button>
          <Button color="primary" outline href={routes.account.signup}>
            Sign Up
          </Button>
        </Container>
      </Navbar>
    </div>
    <div style={{ height: 58 }} />
  </Fragment>
);

export default () => {
  const { isAuthenticated, user } = useAuth();
  return isAuthenticated && user ? (
    <LoggedInNav user={user} />
  ) : (
    <LoggedOutNav />
  );
};
