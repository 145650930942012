import React from "react";
import { Container, Row, Col } from "reactstrap";

import Sidebar from "../components/Sidebar";
import FooterNavigation from "../components/FooterNavigation";
import Navigation from "../components/Navigation";
import DirectMessagesList from "../components/DirectMessagesList";
import If from "../components/helpers/If";
import classes from "../utils/classes";

interface Props {
  children: React.ReactNode;
  isPadded?: boolean;
}

export default function MainLayout({ children, isPadded = true }: Props) {
  const shouldShowConversations = false;

  const sizes = shouldShowConversations
    ? { main: { md: 6 }, right: 3 }
    : { main: { md: 9, lg: 10 }, right: 0 };

  const contentClassName = classes(
    { "px-3": isPadded },
    "mh-100 h-100 overflow-auto d-flex"
  );

  const scrollContainerClassName = classes({ "py-3": isPadded }, "h-100 w-100");

  return (
    <div className="vh-100 d-flex flex-column overflow-hidden">
      <Navigation />
      <Container fluid className="overflow-hidden h-100">
        <Row className="h-100 overflow-hidden">
          <Col md={3} lg={2}>
            <div className="d-none d-md-block h-100 ml-auto">
              <Sidebar />
            </div>
          </Col>

          <Col {...sizes.main} className="h-100 p-0">
            <div className={contentClassName}>
              <div className={scrollContainerClassName}>
                {children}
                <If test={isPadded}>
                  <div className="pb-3" />
                </If>
              </div>
            </div>
          </Col>

          <If test={shouldShowConversations}>
            <Col md={sizes.right} className="h-100 p-0">
              <DirectMessagesList
                hasBorder={true}
                hasTitle={true}
                hasPadding={true}
              />
            </Col>
          </If>
        </Row>
      </Container>

      <FooterNavigation />
    </div>
  );
}
