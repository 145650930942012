import * as firebase from "firebase/app";

import { ConversationType, Maybe } from "../types";
import useErrorProvider from "./useErrorProvider";
import { every, first, join, isEmpty, negate } from "lodash";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import getDataFromDocument from "../utils/getDataFromDocument";

type ParticipantsList = Array<string | undefined>;

const isNotEmpty = negate(isEmpty);

async function createConversationDocument(
  db: firebase.firestore.Firestore,
  participants: ParticipantsList
) {
  try {
    const docRef = await db.collection("conversations").add({
      participants,
    });
    const doc = await db.collection("conversations").doc(docRef.id).get();
    return getDataFromDocument(doc);
  } catch (e) {
    console.error(
      `Failed to create conversation root document for ${join(participants)}`
    );
    console.error(e);
  }
}

export default function useGetOrCreateConversation() {
  const { reportError } = useErrorProvider();

  return async (participants: ParticipantsList) => {
    const db = firebase.firestore();

    if (every(participants, isNotEmpty)) {
      try {
        const result = await db
          .collection(`conversations`)
          .where("participants", "==", participants)
          .get();

        const existing: Maybe<ConversationType> = first(
          getDataFromDocuments(result.docs)
        );

        if (existing) {
          return existing;
        } else {
          const created = await createConversationDocument(db, participants);
          return created;
        }
      } catch (e) {
        reportError(e, "useGetOrCreateConversation");
      }
    }
  };
}
