import React from "react";

import MainLayout from "./MainLayout";
import SmallGroupForm from "../components/SmallGroupForm";
import { SmallGroupType } from "../types";
import usePostToSmallGroups from "../hooks/usePostToSmallGroups";

export default function NewSmallGroupPage() {
  const postToSmallGroups = usePostToSmallGroups();
  const onUpdate = async (smallGroup: SmallGroupType) => {
    await postToSmallGroups(smallGroup);
  };

  return (
    <MainLayout>
      <h1 className="h4">Organize a Mutual Assistance Group</h1>
      <SmallGroupForm
        readonly={false}
        onUpdate={onUpdate}
        updateLabel="Submit"
      />
    </MainLayout>
  );
}
