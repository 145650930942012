import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { DirectMessageType } from "../types";

const MERGE = { merge: true };

export default function useUpdateDirectMessage() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return async (
    conversation: string,
    id: string,
    updates: DirectMessageType
  ) => {
    try {
      await db
        .doc(`conversations/${conversation}/messages/${id}`)
        .set(updates, MERGE);
    } catch (error) {
      reportError(error.message, "useUpdateDirectMessage");
    }
  };
}
