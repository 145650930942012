import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import NewFeedItemForm from "./NewFeedItemForm";
import useRouter from "../hooks/useRouter";
import routes from "../routes";

interface Props {
  isVisible: boolean;
  toggle: () => void;
  onPosted: () => void;
}

export default function NewFeedItemModal({
  toggle,
  isVisible,
  onPosted,
}: Props) {
  const { routeTo } = useRouter();
  const onPostComplete = () => {
    onPosted();
    routeTo(routes.myFeed);
  };
  return (
    <Modal isOpen={isVisible} toggle={toggle}>
      <ModalHeader toggle={toggle}>New Post</ModalHeader>
      <ModalBody>
        <NewFeedItemForm onPosted={onPostComplete} />
      </ModalBody>
    </Modal>
  );
}
