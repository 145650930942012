import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import getDataFromDocument from "../utils/getDataFromDocument";
import { ProfileType } from "../types";

const DEFAULT_CONTEXT_VALUE = { profile: {}, isLoading: false };

export const getProfile = async (user: firebase.User) => {
  const db = firebase.firestore();
  const doc = await db.collection("profile").doc(user.uid).get();
  return getDataFromDocument(doc);
};

type ContextProps = {
  profile: ProfileType;
  isLoading: boolean;
};

const AccountProfileContext = createContext<ContextProps>(
  DEFAULT_CONTEXT_VALUE
);
const { Provider } = AccountProfileContext;

interface ProviderProps {
  children: React.ReactNode;
}

export function AccountProfileProvider({ children }: ProviderProps) {
  const [profile, setProfile] = useState({});
  const [isLoading, setLoading] = useState(false);
  const { user } = useAuth();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (user && user.displayName) {
      setLoading(true);
      const db = firebase.firestore();
      db.collection("profile")
        .doc(user.displayName || undefined)
        .get()
        .then((doc) => {
          setProfile(getDataFromDocument(doc));
        })
        .catch((e) => reportError(e, "useAccountProfile"))
        .finally(() => setLoading(false));
    }
  }, [reportError, user]);

  const contextValue = useMemo(
    () => ({ profile, isLoading }),
    [profile, isLoading]
  );

  return <Provider value={contextValue}>{children}</Provider>;
}

export default function useAccountProfile(): ContextProps {
  return useContext(AccountProfileContext);
}
