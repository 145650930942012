import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { ResourceType } from "../types";

const MERGE = { merge: true };

export default function useUpdateResource() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return async (resource: string, updates: ResourceType) => {
    try {
      await db.doc(`resources/${resource}`).set(updates, MERGE);
    } catch (error) {
      reportError(error.message, "useUpdateResource");
    }
  };
}
