import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import React, { createContext, useContext, useState, useEffect } from "react";

const SupportProfileContext = createContext();

export function SupportProfileProvider({ children }) {
  const [profile, setProfile] = useState();
  const { user } = useAuth();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (user) {
      user
        .getIdTokenResult(/* forceRefresh */ true)
        .then((idToken) => {
          setProfile(idToken.claims);
        })
        .catch((e) => reportError(e, "useSupportProfile"));
    }
  }, [reportError, user]);

  return (
    <SupportProfileContext.Provider value={profile}>
      {children}
    </SupportProfileContext.Provider>
  );
}

export default function useSupportProfile() {
  return useContext(SupportProfileContext);
}
