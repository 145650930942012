import { useEffect } from "react";
import { useHistory } from "react-router";

const AIRLYTICS_ENDPOINT =
  "https://mag-community-analytics.netlify.app/.netlify/functions/event?pid=appO6OEoUgWAROZb6";

async function trackPageView(pathname: string) {
  if (process.env.NODE_ENV === "production") {
    await fetch(`${AIRLYTICS_ENDPOINT}&pathname=${pathname}`);
  } else {
    console.info(`Would have tracked pageview`, pathname);
  }
}

export default function useAirlytics() {
  const history = useHistory();

  // Track the current page
  useEffect(() => {
    const { pathname } = window.location;
    trackPageView(pathname);
  }, []);

  // Listen to all future page changes and track those
  history.listen(async ({ pathname }) => {
    await trackPageView(pathname);
  });
}

export function AirlyticsConnection() {
  useAirlytics();
  return null;
}
