import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocument from "../utils/getDataFromDocument";
import { ProfileType } from "../types";

export default function useProfile(username: string): ProfileType {
  const [profile, setProfile] = useState({});
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (username) {
      const db = firebase.firestore();
      db.collection("profile")
        .doc(username)
        .get()
        .then((doc) => {
          setProfile(getDataFromDocument(doc));
        })
        .catch((e) => reportError(e, "useProfile"));
    }
  }, [reportError, username]);

  return profile;
}
