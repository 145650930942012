import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "leaflet/dist/leaflet.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/performance";
import "firebase/firestore";
import "firebase/storage";

import * as Sentry from "@sentry/react";

import { firebase as firebaseConfig } from "./configuration";

if (process.env.NODE_ENV === "development") {
  console.log(firebaseConfig);
  import("./devtools");
}

try {
  firebase.initializeApp(firebaseConfig);
} catch (e) {
  console.error(`Error initializing firebase library`);
}

Sentry.init({
  dsn: "https://ffdacd5d19eb4f95ab8673779819c6ca@o232675.ingest.sentry.io/5391856",
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
