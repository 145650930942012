import React, { FormEvent, KeyboardEvent } from "react";
import MainLayout from "./MainLayout";
import { map, isEmpty } from "lodash";
import FeedItem from "../components/FeedItem";
import If from "../components/helpers/If";
import { Spinner, Form, Input, Button } from "reactstrap";
import useDiscussionResponses from "../hooks/useDiscussionResponses";
import { useParams } from "react-router";
import useDiscussion from "../hooks/useDiscussion";
import useFormInputState from "../hooks/useFormInputState";
import usePostResponseToDiscussion from "../hooks/usePostResponseToDiscussion";
import canModify from "../utils/canModify";
import useAuth from "../hooks/useAuth";
import routes from "../routes";
import usePostNotifications from "../hooks/usePostNotifications";

export default function DiscussionsPage() {
  const { id } = useParams();
  const { user } = useAuth();
  const postNotifications = usePostNotifications();
  const { discussion, isLoading: isLoadingDiscussion } = useDiscussion(id);
  const { responses, isLoading: isLoadingResponses } =
    useDiscussionResponses(id);

  const isLoading = isLoadingDiscussion || isLoadingResponses;

  const postResponseToDiscussion = usePostResponseToDiscussion();
  const [response, onChangeResponse] = useFormInputState("");

  const canEdit = canModify(user, discussion);

  const submitResponse = async () => {
    onChangeResponse({ target: { value: "" } });

    const responseId = await postResponseToDiscussion(id, response);

    // If all information is present, send a notification to the discussion author
    if (
      discussion &&
      discussion.id &&
      discussion.path &&
      responseId &&
      user &&
      user.uid !== discussion.uid
    ) {
      await postNotifications(
        routes.discussionResponse(discussion.id, responseId),
        [discussion.uid],
        "response"
      );
    }
  };

  const onKeydown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13 && e.ctrlKey) {
      submitResponse();
    }
  };

  const onPostResponse = async (e: FormEvent) => {
    e.preventDefault();

    submitResponse();
  };

  return (
    <MainLayout isPadded={false}>
      <div className="overflow-auto d-flex flex-column h-100 border-left py-3">
        <h1 className="h4 mb-0 px-3">Discussion</h1>
        <If test={discussion}>
          <div className="px-3 border-bottom">
            <FeedItem key="root" item={discussion} canEdit={canEdit} />
          </div>
        </If>

        {map(responses, (response) => (
          <div key={response.id} className="border-bottom">
            <div className="pl-5">
              <FeedItem
                key={response.id}
                item={response}
                canEdit={canModify(user, response)}
              />
            </div>
          </div>
        ))}

        <If test={!isLoading && isEmpty(responses)}>
          <div className="p-3 text-center text-muted">
            <em>Be the first to respond...</em>
          </div>
        </If>

        <If test={isLoading}>
          <div className="text-center" style={{ margin: "200px 0px" }}>
            <Spinner color="primary" size="md" />
          </div>
        </If>

        <If test={!isLoading}>
          <div className="p-3">
            <Form onSubmit={onPostResponse}>
              <Input
                type="textarea"
                placeholder="Your response..."
                value={response}
                onChange={onChangeResponse}
                onKeyDown={onKeydown}
              />
              <div className="mt-3 d-flex justify-content-end">
                <Button type="submit" color="success">
                  Reply
                </Button>
              </div>
            </Form>
          </div>
        </If>
      </div>
    </MainLayout>
  );
}
