import React from "react";
import { Spinner } from "reactstrap";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";

import MainLayout from "./MainLayout";
import SmallGroupDisplay from "../components/SmallGroupDisplay";
import If from "../components/helpers/If";
import useSmallGroup from "../hooks/useSmallGroup";

export default function SmallGroupPage() {
  const { group } = useParams();
  const { smallGroup } = useSmallGroup(group);

  return (
    <MainLayout>
      <If test={!isEmpty(smallGroup)}>
        <SmallGroupDisplay smallGroup={smallGroup} />
      </If>
      <If test={isEmpty(smallGroup)}>
        <div className="text-center" style={{ margin: "200px 0px" }}>
          <Spinner color="primary" size="md" />
        </div>
      </If>
    </MainLayout>
  );
}
