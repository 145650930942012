import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

export default function useDeleteRecommendation() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return (id: string) =>
    db
      .doc(`recommendation/${id}`)
      .delete()
      .catch((e) => reportError(e, "useDeleteRecommendation"));
}
