import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormText,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner,
} from "reactstrap";
import Icon from "@mdi/react";

import * as firebase from "firebase/app";

import useRouter from "../../hooks/useRouter";
import routes from "../../routes";
import Layout from "./Layout";
import If from "../../components/helpers/If";
import useErrorProvider from "../../hooks/useErrorProvider";
import BusyButton from "../../components/BusyButton";
import useCheckUsernameAvailability from "../../hooks/useCheckUsernameAvailability";
import useMarkusernameUnavailable from "../../hooks/useMarkUsernameUnavailable";
import { isEmpty } from "lodash";
import {
  mdiCheckCircle,
  mdiCancel,
  mdiEyeOutline,
  mdiEyeOffOutline,
} from "@mdi/js";

export default () => {
  const { reportError } = useErrorProvider();
  const { routeTo } = useRouter();

  const [isBusy, setBusy] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const { isAvailable, isChecking } = useCheckUsernameAvailability(name);
  const markUsernameUnavailable = useMarkusernameUnavailable();

  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    setBusy(true);

    try {
      const { user } = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);

      await user.updateProfile({
        displayName: name,
      });

      await markUsernameUnavailable(user);

      routeTo(routes.account.welcome);
    } catch (error) {
      reportError(error);
    } finally {
      setBusy(false);
    }
  };

  const isNotAvailable = !isAvailable && !isEmpty(name);
  const isUsernameValid = isAvailable && !isEmpty(name);

  const usernameIcon = isUsernameValid
    ? mdiCheckCircle
    : isNotAvailable
    ? mdiCancel
    : null;
  const usernameIconClassName = isChecking
    ? "text-muted"
    : isUsernameValid
    ? "text-success"
    : isNotAvailable
    ? "text-danger"
    : null;

  const passwordIcon = isPasswordVisible ? mdiEyeOutline : mdiEyeOffOutline;

  const onTogglePasswordVisibility = () => setPasswordVisible((b) => !b);

  return (
    <Layout.Container>
      <h3>Sign Up</h3>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label for="name">Your Username</Label>
          <InputGroup>
            <Input
              name="name"
              type="text"
              autoFocus
              autoComplete="off"
              placeholder="Your name"
              value={name}
              onChange={onChangeName}
            />
            <If test={usernameIcon}>
              <InputGroupAddon addonType="append">
                <InputGroupText className={usernameIconClassName}>
                  <If test={isChecking}>
                    <Spinner size="sm" />
                  </If>
                  <If test={!isChecking}>
                    <Icon path={usernameIcon} size={0.7} />
                  </If>
                </InputGroupText>
              </InputGroupAddon>
            </If>
          </InputGroup>
          <If test={!isChecking && isAvailable && !isEmpty(name)}>
            <FormText color="success">Username is available.</FormText>
          </If>
          <If test={!isChecking && isNotAvailable}>
            <FormText color="danger">Username is not available.</FormText>
          </If>
          <If test={isEmpty(name)}>
            <FormText>
              Please enter a username that you would like to have.
            </FormText>
          </If>
          <If test={isChecking}>
            <FormText>Checking username availability...</FormText>
          </If>
        </FormGroup>
        <FormGroup>
          <Label for="email">Your Email</Label>
          <Input
            name="email"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={onChangeEmail}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Your Password</Label>
          <InputGroup>
            <Input
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              placeholder="Your password"
              value={password}
              onChange={onChangePassword}
            />
            <InputGroupAddon addonType="append">
              <Button
                type="button"
                color="secondary"
                outline
                onClick={onTogglePasswordVisibility}
              >
                <Icon path={passwordIcon} size={0.7} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
        <Layout.Footer>
          <div>
            Already have an acount?&nbsp;
            <a href={routes.account.signin}>Sign In</a>
          </div>
          <BusyButton
            type="submit"
            color="primary"
            isBusy={isBusy}
            disabled={isChecking || !isAvailable || isEmpty(name)}
          >
            Sign Up
          </BusyButton>
        </Layout.Footer>
      </Form>
    </Layout.Container>
  );
};
