import React, { FormEvent, ChangeEvent, useState } from "react";
import { Input, Form } from "reactstrap";
import usePersistentState from "../hooks/usePersistentState";
import { isEmpty, map, trim } from "lodash";
import usePostToFeed from "../hooks/usePostToFeed";
import useErrorProvider from "../hooks/useErrorProvider";
import BusyButton from "./BusyButton";
import { useNotification } from "./Notification";
import useAllProfiles from "../hooks/useAllProfiles";
import routes from "../routes";
import usePostNotifications from "../hooks/usePostNotifications";

interface Props {
  onPosted?: (message: string) => void;
}

export default function NewFeedItemForm(props: Props) {
  const [isPosting, setPosting] = useState(false);
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();
  const [message, setMessage] = usePersistentState("", "feed.newMessage");
  const { profiles, isLoading: isLoadingProfiles } = useAllProfiles();

  const canPost = !isEmpty(trim(message)) && !isLoadingProfiles;

  const onChangeMessage = (e: ChangeEvent<HTMLInputElement>) =>
    setMessage(e.target.value);

  const postToFeed = usePostToFeed();
  const postNotifications = usePostNotifications();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setPosting(true);

      const discussionId = await postToFeed(message);

      if (discussionId) {
        await postNotifications(
          routes.discussion(discussionId),
          map(profiles, "uid"),
          "discussion"
        );
      }

      setMessage("");
      notify("Your message has been posted to discussions", "success");

      if (props.onPosted) {
        props.onPosted(message);
      }
    } catch (e) {
      reportError(`Failed to post your message. Please try again later.`);
    } finally {
      setPosting(false);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        type="textarea"
        placeholder="What's on your mind?"
        rows={10}
        value={message}
        onChange={onChangeMessage}
      />
      <div className="mt-3 d-flex align-items-center justify-content-between">
        <small className="text-muted">
          Your thoughts will be visible to all signed in users.
        </small>
        <BusyButton
          isBusy={isPosting}
          color="success"
          type="submit"
          className="shadow"
          disabled={!canPost}
        >
          Post
        </BusyButton>
      </div>
    </Form>
  );
}
