import React, { useState, Fragment } from "react";
import {
  ListGroup,
  ListGroupItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Icon from "@mdi/react";
import {
  mdiAccountCircle,
  mdiAccountMultiple,
  mdiLogoutVariant,
  mdiPaletteSwatch,
  mdiMenuDown,
} from "@mdi/js";
import { map, size, startsWith } from "lodash";
import { useLocation } from "react-router";

import SecondaryNavigation from "./SecondaryNavigation";
import toggle from "../utils/toggle";
import NewFeedItemModal from "./NewFeedItemModal";
import routes from "../routes";
import IfSupport from "./helpers/IfSupport";
import IfDevelopment from "./helpers/IfDevelopment";
import useAuth from "../hooks/useAuth";
import useAccountProfile from "../hooks/useAccountProfile";
import getDisplayName from "../utils/getDisplayName";
import useNotifications from "../hooks/useNotifications";
import If from "./helpers/If";
import Logo from "./Logo";
import AppName, { APP_NAME } from "./AppName";

interface SidebarItemProps {
  icon: string;
  href: string;
  label: string;
  count: number;
}

const countBadgeStyle = {
  width: 16,
  height: 16,
  lineHeight: "16px",
  fontSize: 10,
};

function SidebarItem({ icon, href, label, count }: SidebarItemProps) {
  const { pathname } = useLocation();
  const isActive = href === pathname || startsWith(pathname, href);
  return (
    <ListGroupItem
      tag="a"
      action
      className="d-flex"
      href={href}
      active={isActive}
    >
      <Icon path={icon} size={1.0} className="flex-shrink-0" />
      <div className="ml-2 d-none d-md-block">{label}</div>

      <If test={count}>
        <div
          className="ml-1 d-flex align-items-center justify-content-center rounded-circle bg-danger text-white"
          style={countBadgeStyle}
        >
          {count}
        </div>
      </If>
    </ListGroupItem>
  );
}

export default function Sidebar() {
  const { user } = useAuth();
  const { profile } = useAccountProfile();
  const { notifications } = useNotifications();
  const [isNavOpen, setNavOpen] = useState(false);
  const onToggleNav = () => setNavOpen(!isNavOpen);

  const [isPosting, setPosting] = useState(false);

  const onTogglePosting = () => setPosting(toggle);

  const onPosted = () => setPosting(false);

  const countOfNotifications = Math.min(size(notifications), 9);
  const counts = {
    [routes.myNotifications]: countOfNotifications,
  };

  return (
    <Fragment>
      <div className="pt-3 d-flex flex-column justify-content-between h-100">
        <div>
          <ListGroup>
            <ListGroupItem tag="a" action href={routes.discover}>
              <strong className="text-primary d-flex align-items-center">
                <Logo size={0.6} />
                <div className="ml-1">
                  <AppName />
                </div>
              </strong>
            </ListGroupItem>
            {map(SecondaryNavigation, (props, index) => (
              <SidebarItem key={index} {...props} count={counts[props.href]} />
            ))}
          </ListGroup>
        </div>

        <div>
          <Dropdown isOpen={isNavOpen} toggle={onToggleNav} size="sm">
            <DropdownToggle
              color="dark"
              block
              outline
              className="d-flex justify-content-between"
            >
              <span className="">{profile.name || getDisplayName(user)}</span>
              <Icon path={mdiMenuDown} size={0.7} />
            </DropdownToggle>

            <DropdownMenu>
              <DropdownItem tag="a" href={routes.myProfile}>
                <Icon path={mdiAccountCircle} size={1} /> Profile
              </DropdownItem>
              <IfSupport>
                <DropdownItem divider />
                <DropdownItem tag="a" href={routes.support.accounts}>
                  <Icon path={mdiAccountMultiple} size={1} /> Manage Accounts
                </DropdownItem>
              </IfSupport>
              <IfDevelopment>
                <DropdownItem divider />
                <DropdownItem tag="a" href={routes.styleguide}>
                  <Icon path={mdiPaletteSwatch} size={1} /> Styleguide
                </DropdownItem>
              </IfDevelopment>
              <DropdownItem divider />
              <DropdownItem tag="a" href={routes.account.signout}>
                <Icon path={mdiLogoutVariant} size={1} /> Sign Out
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <footer className="mb-3 text-muted">
            <small>
              <a href={routes.support.email}>Contact Support</a>
              <br />
              Copyright {APP_NAME}.
              <br />
              All rights reserved.
            </small>
          </footer>
        </div>
      </div>

      <NewFeedItemModal
        isVisible={isPosting}
        toggle={onTogglePosting}
        onPosted={onPosted}
      />
    </Fragment>
  );
}
