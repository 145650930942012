import React from "react";
import { Button, ButtonGroup } from "reactstrap";
import { mdiPencilOutline, mdiTrashCanOutline } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  onDelete: () => Promise<void>;
  onEdit: () => void;
}

export default function DirectMessageMenu({ onDelete, onEdit }: Props) {
  return (
    <ButtonGroup size="sm" className="bg-white rounded-lg shadow-sm">
      <Button
        className="py-1 px-2 border-0 d-flex align-items-center"
        color="dark"
        outline
        onClick={onEdit}
      >
        <Icon path={mdiPencilOutline} size={0.6} /> Edit
      </Button>
      <Button
        className="py-1 px-2 border-0 d-flex align-items-center"
        color="danger"
        outline
        onClick={onDelete}
      >
        <Icon path={mdiTrashCanOutline} size={0.6} /> Delete
      </Button>
    </ButtonGroup>
  );
}
