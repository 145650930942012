import React, { Fragment } from "react";
import { map } from "lodash";

const PARAGRAPHS = [
  "Find like-minded mutual assistance group members in your area, learn about and attend meetings and training events, get to know others and build relationships based on mutual trust on your own terms.",
];

export default function Introduction() {
  return (
    <Fragment>
      {map(PARAGRAPHS, (text, index) => (
        <p key={index} className="lead">
          {text}
        </p>
      ))}
    </Fragment>
  );
}
