import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect as RouteRedirect,
} from "react-router-dom";

import "./App.css";
import "@mag-net/theme/dist/bootstrap.css";

import { ErrorProvider } from "./hooks/useErrorProvider";
import { NotificationProvider } from "./components/Notification";
import { NotificationsProvider } from "./hooks/useNotifications";
import Route from "./components/helpers/Route";
import ProtectedRoute from "./components/helpers/ProtectedRoute";

import routes from "./routes";

import Styleguide from "./pages/styleguide";
import Landing from "./pages/Landing";
import AccountSignUp from "./pages/account/SignUp";
import AccountSignIn from "./pages/account/SignIn";
import AccountSignOut from "./pages/account/SignOut";
import AccountRecover from "./pages/account/Recover";
import AccountResetPassword from "./pages/account/ResetPassword";
import AccountProfile from "./pages/account/Profile";
import AccountLoading from "./pages/account/Loading";
import AccountAction from "./pages/account/Action";
import AccountToken from "./pages/account/Token";
import MyProfile from "./pages/MyProfile";
import MyFeed from "./pages/MyFeed";
import UserProfile from "./pages/Profile";
import UserFeed from "./pages/Feed";
import Redirect from "./pages/Redirect";
import SupportDashboard from "./pages/support/Dashboard";
import SupportAccounts from "./pages/support/Accounts";
import NotFound from "./pages/NotFound";

import { RouterConnection } from "./hooks/useRouter";
import { SupportProfileProvider } from "./hooks/useSupportProfile";
import ScrollRestoration from "./components/helpers/ScrollRestoration";
import ConversationPage from "./pages/Conversation";
import { AccountProfileProvider } from "./hooks/useAccountProfile";
import ConversationsPage from "./pages/Conversations";
import { ConversationsProvider } from "./hooks/useConversationsForUser";
import DiscussionsPage from "./pages/Discussions";
import DiscussionPage from "./pages/Discussion";
import DiscoverPage from "./pages/Discover";
import { ProfilesProvider } from "./hooks/useAllProfiles";
import MyNotifications from "./pages/MyNotifications";
import ResourcesPage from "./pages/Resources";
import SmallGroupsPage from "./pages/SmallGroups";
import NewSmallGroupPage from "./pages/NewSmallGroup";
import EditSmallGroupPage from "./pages/EditSmallGroup";
import SmallGroupPage from "./pages/SmallGroup";
import EventsPage from "./pages/Events";
import { AirlyticsConnection } from "./hooks/useAirlytics";
import IntelPage from "./pages/Intel";

function App() {
  return (
    <NotificationProvider>
      <ErrorProvider>
        <AccountProfileProvider>
          <NotificationsProvider>
            <ProfilesProvider>
              <SupportProfileProvider>
                <ConversationsProvider>
                  <Router>
                    <RouterConnection />
                    <AirlyticsConnection />
                    <ScrollRestoration />

                    <Switch>
                      <Route exact path={routes.landing}>
                        <Landing />
                      </Route>

                      <Route exact path={routes.intel}>
                        <IntelPage />
                      </Route>

                      <ProtectedRoute exact path={routes.discover}>
                        <DiscoverPage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.allDiscussions}>
                        <DiscussionsPage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.discussion()}>
                        <DiscussionPage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.myProfile}>
                        <MyProfile />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.myFeed}>
                        <MyFeed />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.myNotifications}>
                        <MyNotifications />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.user()}>
                        <UserProfile />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.feed()}>
                        <UserFeed />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.conversations}>
                        <ConversationsPage />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.conversation()}>
                        <ConversationPage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.resources}>
                        <ResourcesPage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.groups.list}>
                        <SmallGroupsPage />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.groups.create}>
                        <NewSmallGroupPage />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.groups.view()}>
                        <SmallGroupPage />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.groups.edit()}>
                        <EditSmallGroupPage />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.events.landing}>
                        <EventsPage />
                      </ProtectedRoute>

                      <Route exact path={routes.account.signup}>
                        <AccountSignUp />
                      </Route>
                      <Route path={routes.account.signin}>
                        <AccountSignIn />
                      </Route>
                      <Route exact path={routes.account.signout}>
                        <AccountSignOut />
                      </Route>
                      <Route exact path={routes.account.recover}>
                        <AccountRecover />
                      </Route>
                      <Route exact path={routes.account.reset}>
                        <AccountResetPassword />
                      </Route>
                      <Route exact path={routes.account.action}>
                        <AccountAction />
                      </Route>
                      <Route exact path={routes.account.token}>
                        <AccountToken />
                      </Route>

                      <ProtectedRoute exact path={routes.account.loading}>
                        <AccountLoading />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.account.welcome}>
                        <AccountProfile />
                      </ProtectedRoute>

                      <ProtectedRoute exact path={routes.support.dashboard}>
                        <SupportDashboard />
                      </ProtectedRoute>
                      <ProtectedRoute exact path={routes.support.accounts}>
                        <SupportAccounts />
                      </ProtectedRoute>

                      <Route exact path={routes.styleguide}>
                        <Styleguide />
                      </Route>

                      {/* Redirects */}
                      <RouteRedirect
                        exact
                        from={routes.home}
                        to={routes.discover}
                      />
                      <RouteRedirect
                        exact
                        from={routes.dashboard}
                        to={routes.discover}
                      />

                      <Route exact path={routes.redirect()}>
                        <Redirect />
                      </Route>

                      {/* Not found */}
                      <Route path="*">
                        <NotFound />
                      </Route>
                    </Switch>
                  </Router>
                </ConversationsProvider>
              </SupportProfileProvider>
            </ProfilesProvider>
          </NotificationsProvider>
        </AccountProfileProvider>
      </ErrorProvider>
    </NotificationProvider>
  );
}

export default App;
