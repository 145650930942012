import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { RecommendationType } from "../types";

const MERGE = { merge: true };

export default function useUpdateRecommendation() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return async (id: string, updates: RecommendationType) => {
    try {
      await db.doc(`recommendation/${id}`).set(updates, MERGE);
    } catch (error) {
      reportError(error.message, "useUpdateRecommendation");
    }
  };
}
