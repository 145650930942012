import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import useSupportProfile from "./useSupportProfile";
import { NotificationType } from "../types";

type ContextProps = {
  notifications: Array<NotificationType>;
};

const defaultContextValue = {
  notifications: [],
};

const Context = createContext<ContextProps>(defaultContextValue);
const { Provider } = Context;

interface Props {
  children: React.ReactNode;
}

export function NotificationsProvider({ children }: Props) {
  const [notifications, setNotifications] = useState([]);
  const { user } = useAuth();
  const supportProfile = useSupportProfile();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (user) {
      const db = firebase.firestore();
      return db
        .collection("notifications")
        .where("uid", "==", user.uid)
        .onSnapshot({
          next(result) {
            setNotifications(getDataFromDocuments(result.docs));
          },
          error: (e) => reportError(e, "useNotifications"),
        });
    }
  }, [reportError, user, supportProfile]);

  const contextValue = useMemo(() => ({ notifications }), [notifications]);

  return <Provider value={contextValue}>{children}</Provider>;
}

type HookReturnValue = {
  notifications: Array<NotificationType>;
};

export default function useNotifications(): HookReturnValue {
  return useContext(Context);
}
