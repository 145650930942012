import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import useAuth from "./useAuth";

export default function usePostResponseToDiscussion() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  return async (discussion: string, message: string) => {
    if (user) {
      const path = `discussion/${discussion}/responses`;
      try {
        const doc = await db.collection(path).add({
          message,
          uid: user.uid,
          created: firebase.firestore.Timestamp.now(),
          author: user.displayName,
        });

        return doc.id;
      } catch (e) {
        reportError(e, "usePostResponseToDiscussion");
      }
    }
    return Promise.resolve();
  };
}
