import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import { SmallGroupType } from "../types";
import useSupportProfile from "./useSupportProfile";
import isSupportAccount from "../utils/isSupportAccount";

type HookReturnValue = {
  groups: Array<SmallGroupType>;
  isLoading: boolean;
};

export default function useAllSmallGroups(): HookReturnValue {
  const supportProfile = useSupportProfile();
  const approvedOnly = !isSupportAccount(supportProfile);
  const [isLoading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const { reportError } = useErrorProvider();

  useEffect(() => {
    setLoading(true);
    const db = firebase.firestore();
    return db
      .collection("groups")
      .orderBy("created", "desc")
      .onSnapshot({
        next(result) {
          setLoading(false);
          setEntries(getDataFromDocuments(result.docs));
        },
        error: (e) => reportError(e, "useAllSmallGroups"),
      });
  }, [reportError, approvedOnly]);

  return { groups: entries, isLoading };
}
