import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

type DocumentWithPath = {
  path?: string;
};

export default function useDeleteDocument() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return ({ path }: DocumentWithPath) =>
    path &&
    db
      .doc(path)
      .delete()
      .catch((e) => reportError(e, "useDeleteDocument"));
}
