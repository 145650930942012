import React from "react";

import routes from "../../routes";
import Layout from "./Layout";
import useAccountProfile from "../../hooks/useAccountProfile";
import useRouter from "../../hooks/useRouter";
import useGeocoder from "../../hooks/useGeocoder";
import useUpdateProfile from "../../hooks/useUpdateProfile";
import useUploadAvatar from "../../hooks/useUploadAvatar";
import formatAddress from "../../utils/formatAddress";
import { ProfileType } from "../../types";
import { get } from "lodash";
import RegistrationForm from "../../components/RegistrationForm";

export default function Profile() {
  const { routeTo } = useRouter();

  const { profile } = useAccountProfile();

  const lookup = useGeocoder();
  const updateProfile = useUpdateProfile();
  const uploadAvatar = useUploadAvatar();

  const onUpdate = async (
    updatedProfile: ProfileType,
    avatarFile: File | undefined
  ) => {
    const address = formatAddress(updatedProfile);
    const geocodeResult = await lookup(address);
    const location = get(geocodeResult, "center", null);

    const avatarFilename = avatarFile
      ? await uploadAvatar(avatarFile)
      : profile.avatarFilename;
    console.log({
      updatedProfile,
      address,
      geocodeResult,
      location,
      avatarFile,
      avatarFilename,
    });

    await updateProfile({ ...updatedProfile, location, avatarFilename });

    routeTo(routes.discover);
  };

  return (
    <Layout.Container>
      <div className="text-center mb-3">
        <h3>Welcome!</h3>
        <p className="lead">
          First, let's fill out your contact information...
        </p>
      </div>
      <RegistrationForm
        readonly={false}
        profile={profile}
        onUpdate={onUpdate}
        canPreview={false}
        updateLabel="Continue"
      />
    </Layout.Container>
  );
}
