import React, { Fragment, useMemo, useState } from "react";
import { Spinner, ListGroup, ListGroupItem, Button } from "reactstrap";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import useConversationsForUser from "../hooks/useConversationsForUser";
import If from "./helpers/If";
import { isEmpty, map } from "lodash";
import { ConversationType } from "../types";
import useAuth from "../hooks/useAuth";
import useProfiles from "../hooks/useProfiles";
import getNameOrDisplayName from "../utils/getNameOrDisplayName";
import routes from "../routes";
import AvatarDisplay from "./AvatarDisplay";
import classes from "../utils/classes";
import { useParams } from "react-router";
import toggle from "../utils/toggle";
import ProfileList from "./ProfileList";
import useStartConversation from "../hooks/useStartConversation";

interface DirectMessagesListItemProps {
  conversation: ConversationType;
  isCurrentConversation: boolean;
}

function DirectMessagesListItem({
  conversation,
  isCurrentConversation,
}: DirectMessagesListItemProps) {
  const { user } = useAuth();

  const { participants, id } = conversation;
  const otherParticipants = useMemo(
    () => (user ? participants.filter((p) => p !== user.uid) : []),
    [participants, user]
  );

  const { profiles } = useProfiles(otherParticipants);

  const listGroupItemClassName = classes(
    { "bg-light": isCurrentConversation },
    "p-2 rounded-lg mb-2"
  );

  return (
    <ListGroupItem
      tag="button"
      href={routes.conversation(id)}
      action
      className={listGroupItemClassName}
      tabIndex={0}
    >
      {map(profiles, (profile, index) => (
        <div
          key={index}
          className="d-flex align-items-center"
          style={{ marginTop: index > 0 ? -12 : 0 }}
        >
          <AvatarDisplay
            avatarFilename={profile.avatarFilename}
            size={32}
            username={profile.name ?? profile.id}
          />
          <div className="ml-2">{getNameOrDisplayName(profile)}</div>
        </div>
      ))}
    </ListGroupItem>
  );
}

interface Props {
  hasBorder?: boolean;
  hasTitle?: boolean;
  hasPadding?: boolean;
}

export default function ConversationsList({
  hasBorder,
  hasTitle,
  hasPadding,
}: Props) {
  const { user } = useAuth();

  const { conversation: currentConversation } = useParams();
  const { conversations, isLoading } = useConversationsForUser();

  const [isAddingParticipant, setAddingParticipant] = useState(false);
  const onAddParticipant = () => setAddingParticipant(true);
  const onToggleAddParticipant = () => setAddingParticipant(toggle);
  const onCancelAddParticipant = () => setAddingParticipant(false);

  const containerClassName = classes(
    { "border-left": hasBorder },
    "h-100 mh-100 overflow-auto p-1"
  );

  const startConversation = useStartConversation();
  const onSelectParticipant = async (uid: string) => {
    if (user) {
      await startConversation([user.uid, uid]);

      setAddingParticipant(false);
    }
  };

  return (
    <Fragment>
      <div className={containerClassName}>
        <If test={isLoading}>
          <div className="h-100 d-flex align-items-center justify-content-center">
            <Spinner size="sm" color="primary" />
          </div>
        </If>
        <If test={!isEmpty(conversations)}>
          <If test={hasTitle}>
            <h4 className="p-3">Private Messages</h4>
          </If>

          <ListGroup className={hasPadding ? "px-2" : ""}>
            {map(conversations, (conversation, index) => (
              <DirectMessagesListItem
                key={index}
                conversation={conversation}
                isCurrentConversation={currentConversation === conversation.id}
              />
            ))}
          </ListGroup>
        </If>
        <If test={!isLoading}>
          <div className={hasPadding ? "p-2" : ""}>
            <Button color="success" onClick={onAddParticipant}>
              Start a New Private Conversation
            </Button>
          </div>
        </If>
      </div>

      <Modal
        isOpen={isAddingParticipant}
        toggle={onToggleAddParticipant}
        autoFocus
      >
        <ModalHeader toggle={onToggleAddParticipant}>
          New Private Message
        </ModalHeader>
        <ModalBody>
          <ProfileList onSelect={onSelectParticipant} />
        </ModalBody>
        <ModalFooter>
          <Button onClick={onCancelAddParticipant} color="dark">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
}
