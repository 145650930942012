import React, { useState } from "react";
import { Button, Collapse, Spinner } from "reactstrap";
import { groupBy, keys, map, toLower } from "lodash";
import MainLayout from "./MainLayout";
import useAllResources from "../hooks/useAllResources";
import isSupportAccount from "../utils/isSupportAccount";
import useSupportProfile from "../hooks/useSupportProfile";
import If from "../components/helpers/If";
import toggle from "../utils/toggle";
import NewResourceForm from "../components/NewResourceForm";
import Resource from "../components/Resource";
import useAuth from "../hooks/useAuth";
import { ResourceType } from "../types";

function getResourceGroupName({ category = "other" }) {
  return toLower(category);
}

export default function ResourcesPage() {
  const { user } = useAuth();
  const supportProfile = useSupportProfile();
  const { resources, isLoading } = useAllResources();

  const isResourceVisible = (resource: ResourceType) =>
    resource.approved ||
    isSupportAccount(supportProfile) ||
    (user && resource.uid === user.uid);

  const visibleResources = resources.filter(isResourceVisible);
  const groupedResources = groupBy(visibleResources, getResourceGroupName);

  const [isToggled, setToggled] = useState(false);
  const onToggleForm = () => setToggled(toggle);

  return (
    <MainLayout>
      <div className="d-flex flex-column h-100">
        <div className="d-flex justify-content-between">
          <h1 className="h4 mb-0">Resources</h1>
          <If test={!isToggled}>
            <Button color="dark" size="sm" onClick={onToggleForm}>
              Submit
              <span className="d-none d-md-inline"> Resource Suggestion</span>
            </Button>
          </If>
        </div>
        <div className="mb-3">
          <small>
            Resources to support former and future ministers and pastors.
          </small>
        </div>

        <Collapse isOpen={isToggled}>
          <div className="mb-3 rounded shadow bg-white p-3">
            <h2 className="h5">Submit a Resource</h2>
            <NewResourceForm
              onCancel={onToggleForm}
              categorySuggestions={keys(groupedResources)}
            />
          </div>
        </Collapse>

        {map(groupedResources, (resourcesInGroup, groupName) => (
          <div className={groupName}>
            <h3 className="h5">{groupName}</h3>
            {map(resourcesInGroup, (resource) => (
              <Resource key={resource.id} resource={resource} />
            ))}
          </div>
        ))}

        <If test={isLoading}>
          <div className="text-center" style={{ margin: "200px 0px" }}>
            <Spinner color="primary" size="md" />
          </div>
        </If>
      </div>
    </MainLayout>
  );
}
