import React, { FormEvent, ChangeEvent, useState } from "react";
import { Input, Form, FormGroup } from "reactstrap";
import usePersistentState from "../hooks/usePersistentState";
import { isEmpty, trim } from "lodash";
import usePostToRecommendations from "../hooks/usePostToRecommendations";
import useErrorProvider from "../hooks/useErrorProvider";
import BusyButton from "./BusyButton";
import { useNotification } from "./Notification";
import { RecommendationType } from "../types";
import useAuth from "../hooks/useAuth";

interface Props {
  onPosted?: (recommendation: RecommendationType) => void;
}

export default function NewRecommendationForm(props: Props) {
  const { user } = useAuth();
  const [isPosting, setPosting] = useState(false);
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();
  const [title, setTitle] = usePersistentState("", "recommendation.new.title");
  const [comment, setComment] = usePersistentState(
    "",
    "recommendation.new.comment"
  );
  const [href, setHref] = usePersistentState("", "recommendation.new.href");

  const canPost = !isEmpty(trim(title)) && !isEmpty(trim(href));

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);
  const onChangeComment = (e: ChangeEvent<HTMLInputElement>) =>
    setComment(e.target.value);
  const onChangeHref = (e: ChangeEvent<HTMLInputElement>) =>
    setHref(e.target.value);

  const postToRecommendation = usePostToRecommendations();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setPosting(true);

      if (user && user.displayName) {
        const recommendation = {
          title,
          content: comment,
          href,
          author: user.displayName,
        };

        await postToRecommendation(recommendation);

        setComment("");
        setTitle("");
        setHref("");
        notify("Your recommendation has been posted", "success");

        if (props.onPosted) {
          props.onPosted(recommendation);
        }
      }
    } catch (e) {
      reportError(
        `Failed to post your recommendation. Please try again later.`
      );
    } finally {
      setPosting(false);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Input
          type="text"
          placeholder="Resource title"
          value={title}
          onChange={onChangeTitle}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          placeholder="Online link to your recommendation"
          value={href}
          onChange={onChangeHref}
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="textarea"
          placeholder="Your comments on this recommendation (optional)"
          rows={10}
          value={comment}
          onChange={onChangeComment}
        />
      </FormGroup>

      <div className="mt-3 d-flex align-items-center justify-content-between">
        <small className="text-muted">
          Your recommendation will be visible to all signed in users.
        </small>
        <BusyButton
          isBusy={isPosting}
          color="success"
          type="submit"
          className="shadow"
          disabled={!canPost}
        >
          Recommend
        </BusyButton>
      </div>
    </Form>
  );
}
