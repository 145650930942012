import React, { FormEvent, useState } from "react";
import { Input, Form, Button, FormGroup } from "reactstrap";
import { isEmpty, trim } from "lodash";
import useErrorProvider from "../hooks/useErrorProvider";
import BusyButton from "./BusyButton";
import { useNotification } from "./Notification";
import useUpdateRecommendation from "../hooks/useUpdateRecommendation";
import { RecommendationType } from "../types";
import useFormInputState from "../hooks/useFormInputState";

interface Props {
  username: string;
  recommendation: RecommendationType;
  onUpdated: () => void;
  onCancel: () => void;
}

export default function EditRecommendationForm({
  username,
  recommendation,
  onUpdated,
  onCancel,
}: Props) {
  const [isPosting, setPosting] = useState(false);
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();
  const [title, onChangeTitle] = useFormInputState(recommendation.title);
  const [comment, onChangeComment] = useFormInputState(recommendation.content);
  const [href, onChangeHref] = useFormInputState(recommendation.href);

  const canPost = !isEmpty(trim(comment));

  const updateRecommendation = useUpdateRecommendation();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (recommendation.id) {
      try {
        setPosting(true);
        await updateRecommendation(recommendation.id, {
          title,
          content: comment,
          href,
          author: username,
        });

        notify("Your recommendation has been updated", "success");

        onUpdated();
      } catch (e) {
        reportError(
          `Failed to update your recommendation. Please try again later.`
        );
      } finally {
        setPosting(false);
      }
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Input
          type="text"
          placeholder="Resource title"
          value={title}
          onChange={onChangeTitle}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          placeholder="Online link to your recommendation"
          value={href}
          onChange={onChangeHref}
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="textarea"
          placeholder="Your comments on this recommendation (optional)"
          rows={10}
          value={comment}
          onChange={onChangeComment}
        />
      </FormGroup>

      <div className="mt-3 d-flex flex-column flex-lg-row align-items-center justify-content-between">
        <small className="text-muted">
          Your recommendation will be visible to all signed in users.
        </small>
        <div>
          <BusyButton
            isBusy={isPosting}
            color="success"
            type="submit"
            className="shadow"
            disabled={!canPost}
          >
            Update
          </BusyButton>
          <Button color="dark" outline onClick={onCancel} className="ml-2">
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
