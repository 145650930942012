import React, { Fragment } from "react";
import { isEmpty } from "lodash";

import { ProfileType } from "../types";
import AddressPreview from "./AddressPreview";
import If from "./helpers/If";
import formatAddress from "../utils/formatAddress";
import Formatted from "./Formatted";
import ContactInformationDisplay from "./ContactInformationDisplay";

interface Props {
  username: string;
  profile: ProfileType;
}

export default function BiographyDisplay({ username, profile }: Props) {
  const locationHeading = (
    <Fragment>
      <h2 className="h4">Location</h2>
      <div>{formatAddress(profile)}</div>
    </Fragment>
  );

  return (
    <Fragment>
      <div>
        <ContactInformationDisplay
          title={<h2 className="h4">Contact</h2>}
          contact={profile}
        />

        <h2 className="h4">About</h2>
        <Formatted>{profile.bio}</Formatted>

        <If test={isEmpty(profile.bio)}>
          <em>I haven't shared anything else quite yet...</em>
        </If>

        <div className="mt-5" />

        <AddressPreview address={profile} heading={locationHeading} />
      </div>
    </Fragment>
  );
}
