import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import useAuth from "./useAuth";
import { SmallGroupType } from "../types";
import useSupportProfile from "./useSupportProfile";
import isSupportAccount from "../utils/isSupportAccount";

export default function usePostToSmallGroups() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  const supportProfile = useSupportProfile();

  // Automatically approve groups
  // submitted by support users
  const approved = isSupportAccount(supportProfile);

  return (smallgroup: SmallGroupType) => {
    if (user) {
      return db
        .collection("groups")
        .add({
          ...smallgroup,
          approved,
          uid: user.uid,
          created: firebase.firestore.Timestamp.now(),
        })
        .catch((e) => reportError(e, "usePostToSmallGroups"));
    }
    return Promise.resolve();
  };
}
