import React, { FormEvent, ChangeEvent, useState } from "react";
import { Input, Form, FormGroup, Button } from "reactstrap";
import useFormInputState from "../hooks/useFormInputState";
import { get, isEmpty, trim } from "lodash";
import useErrorProvider from "../hooks/useErrorProvider";
import BusyButton from "./BusyButton";
import { useNotification } from "./Notification";
import { ResourceType } from "../types";
import useAuth from "../hooks/useAuth";
import If from "./helpers/If";
import useUpdateResource from "../hooks/useUpdateResource";

interface Props {
  resource?: ResourceType;
  onPosted?: (resource: ResourceType) => void;
  onCancel?: () => void;
}

export default function ResourceForm(props: Props) {
  const { resource: initialResource } = props;

  const { user } = useAuth();
  const [isPosting, setPosting] = useState(false);
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();
  const [title, setTitle] = useFormInputState(
    get(initialResource, "title", "")
  );
  const [comment, setComment] = useFormInputState(
    get(initialResource, "content", "")
  );
  const [href, setHref] = useFormInputState(get(initialResource, "href", ""));

  const canPost = !isEmpty(trim(title)) && !isEmpty(trim(href));

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);
  const onChangeComment = (e: ChangeEvent<HTMLInputElement>) =>
    setComment(e.target.value);
  const onChangeHref = (e: ChangeEvent<HTMLInputElement>) =>
    setHref(e.target.value);

  const updateResource = useUpdateResource();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setPosting(true);

      if (user && user.displayName && initialResource && initialResource.id) {
        const updates = {
          title,
          content: comment,
          href,
          author: user.displayName,
        };

        await updateResource(initialResource.id, updates);

        setComment("");
        setTitle("");
        setHref("");
        notify("Your resource has been updated", "success");

        if (props.onPosted) {
          props.onPosted(initialResource);
        }
      }
    } catch (e) {
      reportError(`Failed to update your resource. Please try again later.`);
    } finally {
      setPosting(false);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Input
          type="text"
          placeholder="Resource title"
          value={title}
          onChange={onChangeTitle}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          placeholder="Online link to your resource"
          value={href}
          onChange={onChangeHref}
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="textarea"
          placeholder="Your comments on this resource (optional)"
          rows={10}
          value={comment}
          onChange={onChangeComment}
        />
      </FormGroup>

      <div className="mt-3 d-flex align-items-center justify-content-end">
        <If test={props.onCancel}>
          <Button
            className="mr-2"
            onClick={props.onCancel}
            color="dark"
            outline
          >
            Cancel
          </Button>
        </If>
        <BusyButton
          isBusy={isPosting}
          color="success"
          type="submit"
          className="shadow"
          disabled={!canPost}
        >
          Update
        </BusyButton>
      </div>
    </Form>
  );
}
