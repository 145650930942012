import React, { Fragment, useEffect, useRef, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useLocation } from "react-router";

import { FeedItemType } from "../types";
import getCreatedTimestampFormatted from "../utils/getCreatedTimestampFormatted";
import Formatted from "./Formatted";
import FeedItemMenu from "./FeedItemMenu";
import If from "./helpers/If";
import toggle from "../utils/toggle";
import EditFeedItemForm from "./EditFeedItemForm";
import routes from "../routes";
import useRouter from "../hooks/useRouter";
import { truncate } from "lodash";
import useDeleteDocument from "../hooks/useDeleteDocument";
import classes from "../utils/classes";

interface Props {
  item: FeedItemType | null;
  canEdit?: boolean;
  canOpen?: boolean;
  canTruncate?: boolean;
}

export default function FeedItem({
  item,
  canEdit,
  canOpen,
  canTruncate,
}: Props) {
  const { routeTo } = useRouter();
  const { hash } = useLocation();

  const isHighlighted = item && `#${item.id}` === hash;
  const targetRef = useRef<HTMLDivElement>(null);

  const username = (item && item.author) || "";
  const deleteDocument = useDeleteDocument();

  const onDelete = async () => {
    if (item && item.id) {
      await deleteDocument(item);
    }
  };

  const [isUpdating, setUpdating] = useState(false);
  const onToggleUpdate = () => setUpdating(toggle);
  const onEdit = () => {
    setUpdating(true);
  };
  const onUpdated = () => setUpdating(false);
  const onCancelUpdate = () => setUpdating(false);

  const onOpen = () => item && routeTo(routes.discussion(item.id));

  useEffect(() => {
    if (isHighlighted && targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isHighlighted]);

  if (!item) {
    return null;
  }

  const message = canTruncate
    ? truncate(item.message, { length: 200 })
    : item.message;

  const props = canOpen
    ? {
        onClick: onOpen,
        tabIndex: 0,
        className: "user-clickable",
      }
    : {};

  const messageClassName = classes(
    {
      "text-success": isHighlighted,
      "text-muted": !isHighlighted,
    },
    "mr-2"
  );

  return (
    <Fragment>
      <div className="my-3" ref={targetRef}>
        <div {...props}>
          <div className="position-relative">
            <div className="d-flex align-items-center justify-content-between">
              <div className={messageClassName}>
                <small>
                  <strong>{item.author}</strong>
                </small>
                <span>&nbsp;&bull;&nbsp;</span>
                <small>{getCreatedTimestampFormatted(item)}</small>
              </div>

              <If test={canEdit}>
                <FeedItemMenu onDelete={onDelete} onEdit={onEdit} />
              </If>
            </div>

            <Formatted>{message}</Formatted>
          </div>
        </div>
      </div>

      <Modal isOpen={isUpdating} toggle={onToggleUpdate} size="lg">
        <ModalHeader toggle={onToggleUpdate}>Edit Post</ModalHeader>
        <ModalBody>
          <EditFeedItemForm
            username={username}
            item={item}
            onUpdated={onUpdated}
            onCancel={onCancelUpdate}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
