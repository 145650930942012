import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

export default function useDeleteDirectMessage() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return (conversation: string, id: string) =>
    db
      .doc(`conversations/${conversation}/messages/${id}`)
      .delete()
      .catch((e) => reportError(e, "useDeleteDirectMessage"));
}
