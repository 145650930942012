import React, { Fragment } from "react";
import { map, split, size, trim } from "lodash";
import classes from "../utils/classes";

interface Props {
  children?: string;
}

interface ParagraphProps extends Props {
  isLast: boolean;
}

function Paragraph({ children, isLast }: ParagraphProps) {
  const className = classes({ "m-0": isLast });
  return <p className={className}>{children}</p>;
}

export default function Formatted({ children }: Props) {
  const paragraphs = split(trim(children) || "", "\n");

  return (
    <Fragment>
      {map(paragraphs, (paragraph, index) => (
        <Paragraph key={index} isLast={index === size(paragraphs) - 1}>
          {paragraph}
        </Paragraph>
      ))}
    </Fragment>
  );
}
