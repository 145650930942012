import routes from "../routes";
import useGetOrCreateConversation from "./useGetOrCreateConversation";
import useRouter from "./useRouter";

export default function useStartConversation() {
  const { routeTo } = useRouter();
  const getOrCreateConversation = useGetOrCreateConversation();
  return async (participants: Array<string | undefined>) => {
    const conversation = await getOrCreateConversation(participants);

    routeTo(routes.conversation(conversation?.id));
  };
}
