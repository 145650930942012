import { mdiEmailOutline, mdiRadioHandheld, mdiTelegram } from "@mdi/js";
import Icon from "@mdi/react";
import { isEmpty, map } from "lodash";
import React, { Fragment, ReactNode } from "react";

import { WithContactInformation } from "../types";

interface Props {
  title: ReactNode;
  contact: WithContactInformation;
}

type ContactType = keyof WithContactInformation;

const CONTACT_TYPES = ["email", "telegram", "radio"] as const;

const icons = {
  email: mdiEmailOutline,
  telegram: mdiTelegram,
  radio: mdiRadioHandheld,
} as const;

interface ItemProps {
  type: ContactType;
  value?: string;
}

function Item({ type, value }: ItemProps) {
  if (isEmpty(value)) {
    return null;
  }

  return (
    <div className="d-flex align-items-center my-2">
      <Icon path={icons[type]} size={0.7} />
      <div className="mx-1">{type}</div>
      <div>{value}</div>
    </div>
  );
}

export default function ContactInformationDisplay({ title, contact }: Props) {
  if (CONTACT_TYPES.every((type) => isEmpty(contact[type]))) {
    return null;
  }

  return (
    <Fragment>
      {title}
      {map(CONTACT_TYPES, (type) => (
        <Item type={type} value={contact[type]} />
      ))}
    </Fragment>
  );
}
