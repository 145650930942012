import React, { useMemo } from "react";
import { ProfileType } from "../types";
import { get } from "lodash";
import formatAddress from "../utils/formatAddress";
import routes from "../routes";
import getProfileUsername from "../utils/getProfileUsername";
import { Button } from "reactstrap";
import If from "./helpers/If";
import useAuth from "../hooks/useAuth";
import getDisplayName from "../utils/getDisplayName";
import useStartConversation from "../hooks/useStartConversation";

interface Props {
  profile: ProfileType;
}

export default function ProfilePopupBody({ profile }: Props) {
  const username = getProfileUsername(profile);
  const { user } = useAuth();

  const isCurrentUser = username === getDisplayName(user);

  const startConversation = useStartConversation();
  const participants = useMemo(
    () => [user?.uid, profile?.uid],
    [user, profile]
  );

  const onMessage = async () => {
    await startConversation(participants);
  };

  return (
    <div className="p-1" style={{ minWidth: 200 }}>
      <strong>{get(profile, "name")}</strong>
      <div>{username}</div>
      <div>{formatAddress(profile)}</div>

      <div className="mt-3" />
      <Button block color="primary" href={routes.user(username)}>
        View Profile
      </Button>

      <If test={!isCurrentUser}>
        <div className="mt-2" />
        <Button block color="success" onClick={onMessage}>
          Send Message
        </Button>
      </If>
    </div>
  );
}
