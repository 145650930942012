import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import { FeedItemType } from "../types";

type HookReturnValue = {
  responses: Array<FeedItemType>;
  isLoading: boolean;
};

export default function useDiscussionResponses(topic: string): HookReturnValue {
  const [isLoading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const { reportError } = useErrorProvider();

  useEffect(() => {
    setLoading(true);
    const db = firebase.firestore();
    return db
      .collection(`discussion/${topic}/responses`)
      .orderBy("created", "asc")
      .onSnapshot({
        next(result) {
          setLoading(false);
          setEntries(getDataFromDocuments(result.docs));
        },
        error: (e) => reportError(e, "useDiscussionResponses"),
      });
  }, [reportError, topic]);

  return { responses: entries, isLoading };
}
