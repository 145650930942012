import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import { FeedItemType } from "../types";

type HookReturnValue = { entries: Array<FeedItemType>; isLoading: boolean };

export default function useFeedFromUser(username: string): HookReturnValue {
  const [isLoading, setLoading] = useState(false);
  const [entries, setEntries] = useState([]);
  const { reportError } = useErrorProvider();

  useEffect(() => {
    setLoading(true);
    const db = firebase.firestore();
    return db
      .collection("discussion")
      .where("author", "==", username)
      .orderBy("created", "desc")
      .onSnapshot({
        next(result) {
          setLoading(false);
          setEntries(getDataFromDocuments(result.docs));
        },
        error: (e) => reportError(e, "useFeedFromUser"),
      });
  }, [reportError, username]);

  return { entries, isLoading };
}
