import React from "react";

import MainLayout from "./MainLayout";
import Feed from "../components/Feed";
import ProfileNavigation from "../components/ProfileNavigation";
import getDisplayName from "../utils/getDisplayName";
import useAuth from "../hooks/useAuth";
import NewFeedItemForm from "../components/NewFeedItemForm";

export default function MyFeedPage() {
  const { user } = useAuth();
  const username = getDisplayName(user);

  return (
    <MainLayout>
      <ProfileNavigation />

      <div className="mb-3 rounded shadow bg-white p-3">
        <NewFeedItemForm />
      </div>

      <Feed username={username as string} />
    </MainLayout>
  );
}
