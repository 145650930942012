import React, { Fragment, useState } from "react";
import { truncate } from "lodash";

import { ResourceType } from "../types";
import Formatted from "./Formatted";
import IfSupport from "./helpers/IfSupport";
import { Badge, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import useApproveDocument from "../hooks/useApproveDocument";
import If from "./helpers/If";
import useAuth from "../hooks/useAuth";
import useSupportProfile from "../hooks/useSupportProfile";
import isSupportAccount from "../utils/isSupportAccount";
import toggle from "../utils/toggle";
import ResourceForm from "./ResourceForm";
import IfNotSupport from "./helpers/IfNotSupport";
import { mdiTrashCanOutline } from "@mdi/js";
import { Icon } from "@mdi/react";
import useDeleteDocument from "../hooks/useDeleteDocument";

interface Props {
  resource: ResourceType;
}

export default function Resource({ resource }: Props) {
  const { user } = useAuth();
  const supportProfile = useSupportProfile();

  const isOwner = user && resource.uid === user.uid;

  const canEdit = isOwner || isSupportAccount(supportProfile);

  const approveDocument = useApproveDocument();
  const onApprove = () => approveDocument(resource);

  const deleteDocument = useDeleteDocument();
  const onDelete = () => deleteDocument(resource);

  const formattedHref = truncate(resource.href, {
    separator: "/",
    length: 40,
  });

  const [isUpdating, setUpdating] = useState(false);
  const onToggleUpdating = () => setUpdating(toggle);
  const onCancelUpdate = () => setUpdating(false);

  const onUpdate = () => setUpdating(true);
  const onUpdated = () => setUpdating(false);

  return (
    <Fragment>
      <div className="mb-3 py-1 border-top">
        <div className="d-flex align-items-start justify-content-between">
          <div>
            <a href={resource.href} target="_blank" rel="noopener noreferrer">
              <strong>{resource.title}</strong>
            </a>
            <If test={isOwner}>
              <small>
                <Badge color="secondary" className="ml-2">
                  Owner
                </Badge>
              </small>
            </If>
            <IfNotSupport>
              <If test={!resource.approved}>
                <small>
                  <Badge color="success" className="ml-2">
                    Pending approval
                  </Badge>
                </small>
              </If>
            </IfNotSupport>
            <br />
            <small className="text-muted">{formattedHref}</small>
          </div>

          <div className="mt-1">
            <IfSupport>
              <If test={!resource.approved}>
                <Button onClick={onApprove} color="success" outline size="sm">
                  Approve
                </Button>
              </If>
            </IfSupport>
            <If test={canEdit}>
              <Button
                color="dark"
                outline
                size="sm"
                className="ml-2"
                onClick={onUpdate}
              >
                Edit
              </Button>

              <Button
                color="danger"
                outline
                size="sm"
                className="ml-2"
                onClick={onDelete}
              >
                <Icon path={mdiTrashCanOutline} size={0.7} />
              </Button>
            </If>
          </div>
        </div>

        <Formatted>{resource.content}</Formatted>
      </div>

      <Modal isOpen={isUpdating} toggle={onToggleUpdating}>
        <ModalHeader toggle={onToggleUpdating}>Update Resource</ModalHeader>
        <ModalBody>
          <ResourceForm
            resource={resource}
            onPosted={onUpdated}
            onCancel={onCancelUpdate}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
