import React, { Fragment } from "react";
import { Spinner, Button, Alert } from "reactstrap";
import { Marker, Popup } from "react-leaflet";
import { filter, isEmpty, isNil, map, size } from "lodash";

import MainLayout from "./MainLayout";
import useAccountProfile from "../hooks/useAccountProfile";
import useAllProfiles from "../hooks/useAllProfiles";
import hasLocation from "../utils/hasLocation";
import MapDisplay from "../components/MapDisplay";
import If from "../components/helpers/If";
import ProfilePopupBody from "../components/ProfilePopupBody";
import getNameOrDisplayName from "../utils/getNameOrDisplayName";
import routes from "../routes";
import formatAddress from "../utils/formatAddress";
import Icon from "@mdi/react";
import { mdiMapMarkerQuestionOutline } from "@mdi/js";
import L from "leaflet";
import marker from "leaflet/dist/images/marker-icon.png";
import IfSupport from "../components/helpers/IfSupport";

const MARKER_ICON = L.icon({
  iconUrl: marker,
  iconSize: [25, 41],
  iconAnchor: [13, 41],
});

const DEFAULT_MAP_CENTER = [39.83, -98];

export default function DiscoverPage() {
  const { profile, isLoading: isProfileLoading } = useAccountProfile();
  const { profiles, isLoading } = useAllProfiles();

  const profilesToRender = filter(
    profiles,
    (profile) => !isNil(profile.location)
  );

  const mapCenter = profile?.location ?? DEFAULT_MAP_CENTER;
  const mapZoom = profile ? 9 : 5;

  const markers = map(profilesToRender, (profile) => (
    <Marker
      key={profile.uid}
      radius={4}
      position={profile.location}
      icon={MARKER_ICON}
    >
      <Popup>
        <ProfilePopupBody profile={profile} />
      </Popup>
    </Marker>
  ));

  return (
    <MainLayout>
      <h1 className="h4 mb-3">Discover</h1>

      <If test={!hasLocation(profile) && !isProfileLoading}>
        <Alert color="info" className="shadow">
          <div className="d-flex align-items-start">
            <Icon
              path={mdiMapMarkerQuestionOutline}
              size={2}
              className="flex-shrink-0"
            />
            <div className="ml-3">
              <strong>
                To see other members near you, please add a location to your
                profile.
              </strong>
              <br />
              <small>
                Sharing your location is optional, however we can't let you know
                who is near you without knowing where you are located.
              </small>
              <div className="d-flex justify-content-end mt-3">
                <Button color="info" outline size="sm" href={routes.myProfile}>
                  Update My Profile
                </Button>
              </div>
            </div>
          </div>
        </Alert>
      </If>

      <div className="bg-white rounded shadow-sm mb-3">
        <div className="rounded shadow-sm">
          <MapDisplay center={mapCenter} zoom={mapZoom} height="65vh">
            {markers}
          </MapDisplay>
        </div>
      </div>

      <IfSupport>
        <If test={!isEmpty(profilesToRender) && !isLoading}>
          <Fragment>
            <div>{size(profilesToRender)} total profiles</div>
            {map(profilesToRender, (p) => (
              <div key={p.id} className="p-2 d-flex align-items-center w-100">
                <div className="mr-auto">
                  <div>{getNameOrDisplayName(p)}</div>
                  <div className="text-muted">{formatAddress(p)}</div>
                </div>
                <div className="text-right">
                  <Button color="link" size="sm" href={routes.user(p.id)}>
                    View Profile
                  </Button>
                </div>
              </div>
            ))}
          </Fragment>
        </If>

        <If test={isLoading || isEmpty(profile)}>
          <div className="text-center p-3">
            <Spinner color="primary" size="md" />
          </div>
        </If>
      </IfSupport>
    </MainLayout>
  );
}
