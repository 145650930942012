import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { useState, useEffect } from "react";
import { FeedItemType } from "../types";
import getDataFromDocument from "../utils/getDataFromDocument";

type HookReturnValue = {
  discussion: FeedItemType | null;
  isLoading: boolean;
};

export default function useDiscussion(topic: string): HookReturnValue {
  const [isLoading, setLoading] = useState(false);
  const [record, setRecord] = useState(null);
  const { reportError } = useErrorProvider();

  useEffect(() => {
    setLoading(true);
    const db = firebase.firestore();
    return db
      .collection(`discussion`)
      .doc(topic)
      .onSnapshot({
        next(result) {
          setLoading(false);
          setRecord(getDataFromDocument(result));
        },
        error(e) {
          reportError(e, "useDiscussion");
        },
      });
  }, [reportError, topic]);

  return { discussion: record, isLoading };
}
