import React from "react";
import { Container, Row, Col } from "reactstrap";
import Layout from "../Layout";

export default function Dashboard() {
  return (
    <Layout>
      <Container>
        <Row>
          <Col>
            <h1>Support Dashboard</h1>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
