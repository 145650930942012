import React from "react";
import { mdiDragVariant } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  size: number | string;
}

export default function Logo({ size }: Props) {
  return <Icon path={mdiDragVariant} size={size} />;
}
