import {
  mdiEmailOutline,
  mdiAccountCircle,
  mdiCompassOutline,
  mdiBellOutline,
  mdiAccountGroupOutline,
  mdiCalendarOutline,
  mdiRadioTower,
} from "@mdi/js";
import routes from "../routes";

interface SecondaryNavigationItemProps {
  icon: string;
  href: string;
  label: string;
  description: string;
  isFeature?: boolean;
  isDesktopOnly?: boolean;
}

export const DESCRIPTIONS = {
  discussions: "Public conversations which all users can read and respond to.",
  conversations: "Your private conversations with other members.",
  events: "Events & training opportunities near you.",
  intel: "Actionable intel and evergreen resources.",
};

const ITEMS = [
  {
    icon: mdiCompassOutline,
    href: routes.discover,
    label: "Discover",
    description: "Find other members located near you.",
    isFeature: true,
  },
  {
    icon: mdiRadioTower,
    href: routes.intel,
    label: "Intel",
    description: DESCRIPTIONS.intel,
    isFeature: true,
  },
  {
    icon: mdiCalendarOutline,
    href: routes.events.landing,
    label: "Events",
    description: DESCRIPTIONS.events,
    isFeature: true,
  },
  {
    icon: mdiAccountGroupOutline,
    href: routes.groups.list,
    label: "Groups",
    description: "Mutual Assistance Groups",
    isFeature: true,
  },
  {
    icon: mdiEmailOutline,
    href: routes.conversations,
    label: "Messages",
    description: DESCRIPTIONS.conversations,
    isFeature: true,
  },
  {
    icon: mdiBellOutline,
    href: routes.myNotifications,
    label: "Notifications",
    description: "Notifications",
  },
  {
    icon: mdiAccountCircle,
    href: routes.myProfile,
    label: "Profile",
    description: "Tell other members about yourself.",
    isDesktopOnly: true,
  },
] as SecondaryNavigationItemProps[];

export const FEATURED = ITEMS.filter((item) => item.isFeature);

export default ITEMS;
