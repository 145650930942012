import React from "react";
import { isEmpty } from "lodash";
import { useParams } from "react-router-dom";

import MainLayout from "./MainLayout";
import If from "../components/helpers/If";
import { Spinner } from "reactstrap";
import useProfile from "../hooks/useProfile";
import ProfileHeader from "../components/ProfileHeader";
import Feed from "../components/Feed";
import ProfileNavigation from "../components/ProfileNavigation";
import { RouterParams } from "../types";

export default function FeedPage() {
  const { username } = useParams() as RouterParams;
  const profile = useProfile(username);

  return (
    <MainLayout>
      <If test={!isEmpty(profile)}>
        <ProfileHeader username={username} profile={profile} />

        <ProfileNavigation />
      </If>
      <If test={isEmpty(profile)}>
        <div className="text-center" style={{ margin: "200px 0px" }}>
          <Spinner color="primary" size="md" />
        </div>
      </If>

      <Feed username={username as string} />
    </MainLayout>
  );
}
