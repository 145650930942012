import * as firebase from "firebase/app";
import { ConversationType, Maybe } from "../types";
import getDataFromDocument from "../utils/getDataFromDocument";

const getConversation = async (
  conversationId: string
): Promise<Maybe<ConversationType>> => {
  const db = firebase.firestore();

  const conversationDoc = await db
    .collection(`conversations`)
    .doc(conversationId)
    .get();
  const conversation: Maybe<ConversationType> =
    getDataFromDocument(conversationDoc);

  return conversation;
};

export default function useGetConversation() {
  return getConversation;
}
