import React, { Fragment } from "react";

import useFeedFromUser from "../hooks/useFeedFromUser";
import { map, isEmpty } from "lodash";
import FeedItem from "./FeedItem";
import If from "./helpers/If";
import getDisplayName from "../utils/getDisplayName";
import useAuth from "../hooks/useAuth";

interface Props {
  username: string;
}

export default function Feed({ username }: Props) {
  const { entries, isLoading } = useFeedFromUser(username);
  const { user } = useAuth();
  const canEdit = username === getDisplayName(user);

  return (
    <Fragment>
      <If test={isEmpty(entries) && !isLoading}>
        <div className="mt-5" />
        <em>I haven't posted any thing yet...</em>
      </If>

      {map(entries, (entry, index) => (
        <FeedItem
          canEdit={canEdit}
          canOpen={true}
          canTruncate={true}
          item={entry}
          key={index}
        />
      ))}
    </Fragment>
  );
}
