import React, { useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import * as firebase from "firebase/app";

import routes from "../../routes";
import Layout from "./Layout";
import useErrorProvider from "../../hooks/useErrorProvider";
import { useNotification } from "../../components/Notification";
import BusyButton from "../../components/BusyButton";

export default function ResetPassword() {
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();

  const [isBusy, setBusy] = useState(false);
  const [email, setEmail] = useState("");

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setBusy(true);
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        notify(
          "Password reset email sent. Please follow the instructions.",
          "success"
        );
      })
      .catch(reportError)
      .finally(() => setBusy(false));
  };
  return (
    <Layout.Container>
      <h3>Reset Password</h3>
      <p>
        Please enter the security code in your password reset email and a new
        password.
      </p>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label for="email">Your Email</Label>
          <Input
            name="email"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={onChangeEmail}
          />
        </FormGroup>
        <Layout.Footer>
          <div>
            Already have an acount?&nbsp;
            <a href={routes.account.signin}>Sign In</a>
          </div>
          <BusyButton type="submit" color="primary" isBusy={isBusy}>
            Reset Password
          </BusyButton>
        </Layout.Footer>
      </Form>
    </Layout.Container>
  );
}
