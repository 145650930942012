import * as firebase from "firebase/app";
import { without } from "lodash";
import useErrorProvider from "./useErrorProvider";
import useAuth from "./useAuth";
import usePostNotifications from "./usePostNotifications";
import routes from "../routes";
import useGetConversation from "./useGetConversation";

export default function useSendDirectionMessage() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();

  const db = firebase.firestore();

  const getConversation = useGetConversation();
  const postNotifications = usePostNotifications();

  return async (conversationId: string, text: string) => {
    if (user) {
      try {
        const conversation = await getConversation(conversationId);
        const recipients = conversation ? conversation.participants : [];

        await postNotifications(
          routes.conversation(conversationId),
          without(recipients, user.uid),
          "message"
        );

        const path = `conversations/${conversationId}/messages`;

        await db.collection(path).add({
          text,
          sender: user.uid,
          created: firebase.firestore.Timestamp.now(),
        });
      } catch (e) {
        reportError(e, "useSendDirectionMessage");
      }
    }
    return Promise.resolve();
  };
}
