import React, { useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";

import * as firebase from "firebase/app";

import routes from "../../routes";
import Layout from "./Layout";
import useErrorProvider from "../../hooks/useErrorProvider";
import useRouter from "../../hooks/useRouter";
import BusyButton from "../../components/BusyButton";
import useQuery from "../../hooks/useQuery";

export default () => {
  const { reportError } = useErrorProvider();
  const { routeTo } = useRouter();

  const [isBusy, setBusy] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const { redirect } = useQuery();

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    setBusy(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => routeTo(routes.account.loadingWithRedirect(redirect)))
      .catch((error) => {
        reportError(error);
      })
      .finally(() => setBusy(false));
  };

  return (
    <Layout.Container>
      <h3>Sign In</h3>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label for="email">Your Email</Label>
          <Input
            name="email"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={onChangeEmail}
          />
        </FormGroup>
        <FormGroup>
          <Label for="password">Your Password</Label>
          <Input
            name="password"
            type="password"
            placeholder="Your password"
            value={password}
            onChange={onChangePassword}
          />
        </FormGroup>
        <Layout.Footer>
          <div>
            Don't have an acount?&nbsp;
            <a href={routes.account.signup}>Sign Up</a>
            <br />
            Forgotten password?&nbsp;
            <a href={routes.account.recover}>Recover Your Account</a>
          </div>
          <BusyButton type="submit" color="primary" isBusy={isBusy}>
            Sign In
          </BusyButton>
        </Layout.Footer>
      </Form>
    </Layout.Container>
  );
};
