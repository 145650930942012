import React, { useState, Fragment } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

import { DirectMessageType } from "../types";
import Formatted from "./Formatted";
import classes from "../utils/classes";
import getCreatedTimestampFormatted from "../utils/getCreatedTimestampFormatted";
import If from "./helpers/If";
import DirectMessageMenu from "./DirectMessageMenu";
import useDeleteDirectMessage from "../hooks/useDeleteDirectMessage";
import toggle from "../utils/toggle";
import EditDirectMessageForm from "./EditDirectMessageForm";
import { useProfileByUid } from "../hooks/useAllProfiles";
import getNameOrDisplayName from "../utils/getNameOrDisplayName";
import createColor from "create-color";

interface Props {
  conversation: string;
  message: DirectMessageType;
  alignment: "left" | "right";
}

const menuContainerStyle = {
  top: -14,
  right: 10,
};

export default function DirectMessage({
  conversation,
  message,
  alignment,
}: Props) {
  const senderProfile = useProfileByUid(message.sender);
  const [hasFocus, setFocused] = useState(false);
  const onFocus = () => setFocused(true);
  const onBlur = () => setFocused(false);

  const deleteDirectMessage = useDeleteDirectMessage();

  const onDelete = async () => {
    if (message.id) {
      await deleteDirectMessage(conversation, message.id);
    }
  };

  const [isUpdating, setUpdating] = useState(false);
  const onToggleUpdate = () => setUpdating(toggle);
  const onEdit = () => {
    setUpdating(true);
  };
  const onUpdated = () => setUpdating(false);
  const onCancelUpdate = () => setUpdating(false);

  const className = classes(
    {
      "justify-content-start mr-3": alignment === "left",
      "justify-content-end ml-3": alignment === "right",
    },
    "d-flex"
  );
  const timestampClassName = classes({
    "text-left": alignment === "left",
    "text-right": alignment === "right",
  });
  const contentClassName = classes(
    {
      "align-items-start": alignment === "left",
      "align-items-end": alignment === "right",
    },
    "d-flex",
    "flex-column"
  );
  const canEdit = alignment === "right";

  const messageClassName = classes(
    {
      border: alignment === "right",
    },
    "p-2 bg-white shadow-sm rounded-sm d-inline-block position-relative"
  );

  const messageProps = canEdit
    ? {
        tabIndex: 0,
        onFocus,
        onBlur,
        onClick: onFocus,
        onMouseEnter: onFocus,
        onMouseLeave: onBlur,
      }
    : {};

  const messageStyle = {
    borderColor: senderProfile ? createColor(senderProfile.id) : "#ffffff",
    borderStyle: "solid",
    borderWidth: 1,
  };

  return (
    <Fragment>
      <div className={className}>
        <div className={contentClassName}>
          <div className={timestampClassName}>
            <small className="text-muted">
              <If test={alignment === "left"}>
                {senderProfile && getNameOrDisplayName(senderProfile)}
                &nbsp;&bull;&nbsp;
              </If>
              {getCreatedTimestampFormatted(message)}
            </small>
          </div>
          <div
            className={messageClassName}
            {...messageProps}
            style={messageStyle}
          >
            <If test={canEdit && hasFocus}>
              <div style={menuContainerStyle} className="position-absolute">
                <DirectMessageMenu onDelete={onDelete} onEdit={onEdit} />
              </div>
            </If>
            <Formatted>{message.text}</Formatted>
          </div>
        </div>
      </div>

      <Modal isOpen={isUpdating} toggle={onToggleUpdate} size="lg">
        <ModalHeader toggle={onToggleUpdate}>Edit Recommendation</ModalHeader>
        <ModalBody>
          <EditDirectMessageForm
            conversation={conversation}
            message={message}
            onUpdated={onUpdated}
            onCancel={onCancelUpdate}
          />
        </ModalBody>
      </Modal>
    </Fragment>
  );
}
