import { useMemo } from "react";

import { ProfileType } from "../types";
import { filter, includes } from "lodash";
import useAllProfiles from "./useAllProfiles";

type HookReturnValue = {
  profiles: Array<ProfileType>;
};

export default function useProfiles(uids: Array<string>): HookReturnValue {
  const { profiles, isLoading } = useAllProfiles();

  const returnValue = useMemo(
    () => ({
      profiles: filter(profiles, (p) => includes(uids, p.uid)),
      isLoading,
    }),
    [uids, profiles, isLoading]
  );

  return returnValue;
}
