import React from "react";
import { Input, Spinner, Table } from "reactstrap";
import useAllProfiles from "../hooks/useAllProfiles";
import getNameOrDisplayName from "../utils/getNameOrDisplayName";
import formatAddress from "../utils/formatAddress";
import { filter, includes, isEmpty, map, toLower } from "lodash";
import useFormInputState from "../hooks/useFormInputState";
import If from "./helpers/If";

interface Props {
  /** Callback for user selection */
  onSelect: (uid: string) => void;
  /** Ignored users by uid */
  ignored?: Array<string>;
}

export default function ProfileList({ onSelect, ignored }: Props) {
  const { profiles, isLoading } = useAllProfiles();

  const [nameFilter, onChangeNameFilter] = useFormInputState("");

  const sansIgnoredProfiles = filter(
    profiles,
    (p) => !includes(ignored, p.uid)
  );

  const profilesToRender = filter(
    sansIgnoredProfiles,
    (p) =>
      includes(toLower(getNameOrDisplayName(p)), toLower(nameFilter)) ||
      includes(toLower(formatAddress(p)), toLower(nameFilter))
  );

  return isLoading ? (
    <div
      className="d-flex align-items-center justify-content-center"
      style={{ height: 200 }}
    >
      <Spinner size="sm" color="primary" />
    </div>
  ) : (
    <div>
      <Input
        type="text"
        className="mb-3"
        placeholder="Start typing to filter..."
        value={nameFilter}
        onChange={onChangeNameFilter}
        autoFocus
        autoComplete="off"
      />
      <Table responsive hover size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Location</th>
          </tr>
        </thead>
        <tbody>
          {map(profilesToRender, (p) => (
            <tr
              key={p.id}
              onClick={() => p.uid && onSelect(p.uid)}
              className="user-clickable"
            >
              <td>{getNameOrDisplayName(p)}</td>
              <td>{formatAddress(p)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      <If test={isEmpty(profilesToRender)}>
        <div className="text-center p-3">
          <em>No profiles match your search.</em>
        </div>
      </If>
    </div>
  );
}
