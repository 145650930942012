import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import { ProfileType } from "../types";
import { isUndefined, mapValues } from "lodash";

const MERGE = { merge: true };

export default function useUpdateProfile() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return (profile: ProfileType) => {
    if (user && user.displayName) {
      const sanitized = mapValues(profile, (property) =>
        isUndefined(property) ? null : property
      );
      return db
        .collection("profile")
        .doc(user.displayName)
        .set(
          {
            ...sanitized,
            updated: firebase.firestore.Timestamp.now(),
            uid: user.uid,
          },
          MERGE
        )
        .catch((error) => {
          reportError(error.message, "useUpdateProfile");
        });
    }
    return Promise.resolve();
  };
}
