import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

type DocumentWithPath = {
  path?: string;
  approved?: boolean;
};

const MERGE = { merge: true };

export default function useApproveDocument() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return ({ path }: DocumentWithPath) =>
    path &&
    db
      .doc(path)
      .set({ approved: true }, MERGE)
      .catch((e) => reportError(e, "useDeleteDocument"));
}
