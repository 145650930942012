import React from "react";
import DirectMessages from "../components/DirectMessages";
import useAuth from "../hooks/useAuth";
import MainLayout from "./MainLayout";
import useSendDirectionMessage from "../hooks/useSendDirectMessage";
import { useParams } from "react-router";
import useDirectMessages from "../hooks/useDirectMessages";
import If from "../components/helpers/If";
import { Spinner } from "reactstrap";

export default function ConversationPage() {
  const { conversation } = useParams();
  const { user } = useAuth();
  const { messages, isLoading } = useDirectMessages(conversation);
  const sendDirectMessage = useSendDirectionMessage();

  const currentUid = (user && user.uid) || "";

  const onSendMessage = (message: string) => {
    sendDirectMessage(conversation, message);
  };

  return (
    <MainLayout isPadded={false}>
      <div className="h-100">
        <If test={isLoading}>
          <div className="h-100 w-100 d-flex align-items-center justify-content-center">
            <Spinner size="md" color="primary" />
          </div>
        </If>
        <If test={!isLoading}>
          <DirectMessages
            conversation={conversation}
            currentUid={currentUid}
            messages={messages}
            onSendMessage={onSendMessage}
          />
        </If>
      </div>
    </MainLayout>
  );
}
