import { useState, useEffect } from "react";
import useDebouncedEffect from "use-debounced-effect-hook";
import * as firebase from "firebase/app";
import { has, isEmpty } from "lodash";
import getDataFromDocument from "../utils/getDataFromDocument";
import useErrorProvider from "../hooks/useErrorProvider";

type UsernameAvailability = {
  isAvailable: boolean;
  isChecking: boolean;
};
export default function useCheckUsernameAvailability(
  username: string
): UsernameAvailability {
  const { reportError } = useErrorProvider();
  const [isChecking, setChecking] = useState(false);
  const [isAvailable, setAvailable] = useState(false);

  useEffect(() => setChecking(!isEmpty(username)), [username]);

  useDebouncedEffect(
    () => {
      if (username) {
        setChecking(true);
        const db = firebase.firestore();
        db.collection("username")
          .doc(username)
          .get({ source: "server" })
          .then((doc) => {
            const data = getDataFromDocument(doc);
            setChecking(false);
            setAvailable(!has(data, "uid"));
          })
          .catch((e) => {
            reportError(e.message, "useCheck");
          });
      }
    },
    [username, reportError],
    500
  );

  return {
    isAvailable,
    isChecking,
  };
}
