import moment from "moment";
import * as firebase from "firebase/app";
import { isNumber, isDate } from "lodash";

type WithCreatedProperty = {
  created?: firebase.firestore.Timestamp;
};

const getTimeForMoment = (
  timestamp: firebase.firestore.Timestamp | Date | number
): Date | number =>
  isNumber(timestamp)
    ? timestamp
    : isDate(timestamp)
    ? timestamp
    : timestamp.toDate();

export default (document: WithCreatedProperty) =>
  document &&
  document.created &&
  moment(getTimeForMoment(document.created)).fromNow();
