import { get, isEmpty } from "lodash";
import { LatLng } from "../types";
import { useCallback } from "react";

export type GeocodeResult = { center: LatLng; type: string; license: string };

export default function useGeocoder() {
  return useCallback(
    async (address: string): Promise<GeocodeResult | undefined> => {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search/${address}?format=json&addressdetails=1&limit=1`,
          {
            method: "GET",
            mode: "cors",
            headers: { Accept: "application/json" },
          }
        );
        const data = await response.json();
        const result = get(data, 0, {});
        const { lat, lon, type, licence } = result;

        return !isEmpty(result)
          ? {
              center: [parseFloat(lat), parseFloat(lon)],
              type,
              // British to American spelling - I'll never remember the second 'c'
              license: licence,
            }
          : undefined;
      } catch (e) {
        console.error(e);
      }
      return undefined;
    },
    []
  );
}
