import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Spinner,
  Fade,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Icon from "@mdi/react";
import { mdiShieldAccount, mdiAccount, mdiMagnify } from "@mdi/js";
import { filter, map, isEmpty, size } from "lodash";

import Layout from "../Layout";
import If from "../../components/helpers/If";
import useAllAccounts from "../../hooks/useAllAccounts";
import usePromoteToSupport from "../../hooks/usePromoteToSupport";
import isMatchingAccount from "../../utils/isMatchingAccount";

function getAccountIcon(account) {
  return account && account.support ? mdiShieldAccount : mdiAccount;
}

function Account(props) {
  const { account, refreshAccounts } = props;
  const [isConfirmingPromote, setConfirmingPromote] = useState(false);
  const promoteToSupport = usePromoteToSupport();

  const onPromote = () => {
    setConfirmingPromote(true);
  };

  const onCancelPromote = () => {
    setConfirmingPromote(false);
  };

  const onConfirmPromote = async () => {
    setConfirmingPromote(false);
    await promoteToSupport(account.uid);
    refreshAccounts();
  };

  return (
    <tr className="position-relative">
      <td>
        <Icon path={getAccountIcon(account)} size={1} />
      </td>
      <td>{account.displayName}</td>
      <td>
        <a href={`mailto:${account.email}`}>{account.email}</a>
      </td>
      <td>
        <code>{account.uid}</code>
      </td>
      <td className="text-right pr-2">
        {account.support ? (
          <span>
            <code>{account.support}</code>&nbsp;support
          </span>
        ) : (
          <Button color="success" outline size="sm" onClick={onPromote}>
            <span className="white-space-pre">Promote to Support</span>
          </Button>
        )}
      </td>

      <If test={isConfirmingPromote}>
        <Fade in={isConfirmingPromote}>
          <div className="overlay bg-success text-white">
            <div className="d-flex px-2 align-items-center justify-content-between h-100">
              <div>Promote {account.email} to a support role?</div>
              <div>
                <Button size="sm" color="success" onClick={onCancelPromote}>
                  Cancel
                </Button>
                <Button
                  size="sm"
                  color="light"
                  onClick={onConfirmPromote}
                  className="ml-2"
                >
                  <span className="white-space-pre">
                    Yes, Promote to Support
                  </span>
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </If>
    </tr>
  );
}

function FilterForm(props) {
  const onSubmit = (e) => e.preventDefault();

  const onChange = (e) => props.onChangeFilter(e.target.value);

  return (
    <Form onSubmit={onSubmit}>
      <InputGroup>
        <Input
          type="text"
          placeholder="Type to filter accounts..."
          onChange={onChange}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Icon path={mdiMagnify} size={0.7} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
    </Form>
  );
}

export default () => {
  const [accounts, isLoading, refreshAccounts] = useAllAccounts();
  const [filterText, setFilterText] = useState("");

  const accountsToRender = filter(accounts, (account) =>
    isMatchingAccount(account, filterText)
  );

  return (
    <Layout>
      <Container>
        <Row className="">
          <Col className="p-3">
            <div className="d-flex flex-column flex-md-row mb-3 align-items-center justify-content-between flex-gap-3">
              <h1 className="m-0 h3">Manage Accounts</h1>
              <FilterForm onChangeFilter={setFilterText} />
            </div>
            <Table responsive striped>
              <thead>
                <tr>
                  <th />
                  <th>Name</th>
                  <th>Email</th>
                  <th>User ID (UID)</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {map(accountsToRender, (account, index) => (
                  <Account
                    key={index}
                    account={account}
                    refreshAccounts={refreshAccounts}
                  />
                ))}
                <If test={isEmpty(accountsToRender)}>
                  <tr>
                    <td colSpan="5" className="text-center">
                      <em>All {size(accounts)} accounts are filtered</em>
                    </td>
                  </tr>
                </If>
              </tbody>
            </Table>

            <If test={isLoading}>
              <div className="text-center my-5">
                <Spinner color="primary" size="1" />
              </div>
            </If>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
