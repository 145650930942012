import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { mdiDotsVertical } from "@mdi/js";
import Icon from "@mdi/react";

interface Props {
  onDelete: () => Promise<void>;
  onEdit: () => void;
}

export default function RecommendationMenu({ onDelete, onEdit }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle close className="py-1 px-2">
        <Icon path={mdiDotsVertical} size={0.7} />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={onEdit}>Edit</DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={onDelete}>Delete</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}
