import { useEffect, useState } from "react";
import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

export default function useIdToken() {
  const auth = firebase.auth();
  const [token, setToken] = useState();
  const { reportError } = useErrorProvider();
  useEffect(
    () =>
      auth &&
      auth.onAuthStateChanged((user) => {
        user
          .getIdToken(/* forceRefresh */ true)
          .then((idToken) => setToken(idToken))
          .catch(reportError);
      })
  );

  return token;
}
