import React from "react";
import useAssetFromStorage from "../hooks/useAssetFromStorage";
import { isEmpty, first, toUpper } from "lodash";
import createColor from "create-color";

interface Props {
  avatarFilename: string | undefined;
  size?: number;
  username: string | undefined;
}

const avatarPreviewStyle = (size: number, username: string | undefined) => ({
  backgroundColor: createColor(username),
  borderColor: createColor(username),
  borderStyle: "solid",
  borderWidth: 1,
  width: size,
  height: size,
  position: "relative" as "relative",
  zIndex: 1,
});

function avatarImageStyle(avatarUrl: string | undefined) {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: !isEmpty(avatarUrl) ? `url(${avatarUrl})` : "none",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
}

export default function AvatarDisplay({
  avatarFilename,
  size = 64,
  username,
}: Props) {
  const avatarUrl = useAssetFromStorage(avatarFilename);

  return (
    <div className="position-relative">
      <div
        style={avatarPreviewStyle(size, username)}
        className="rounded-circle text-white d-flex align-items-center justify-content-center p-0"
      >
        {avatarFilename ? (
          <div
            style={avatarImageStyle(avatarUrl)}
            className="rounded-circle d-flex align-items-center justify-content-center"
          ></div>
        ) : (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <div style={{ lineHeight: `${size / 2}px`, fontSize: size / 2 }}>
              {toUpper(first(username))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
