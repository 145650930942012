import React, { FormEvent, ChangeEvent, useState } from "react";
import { Input, Form, FormGroup, Button } from "reactstrap";
import usePersistentState from "../hooks/usePersistentState";
import { capitalize, isEmpty, map, trim } from "lodash";
import usePostToResources from "../hooks/usePostToResources";
import useErrorProvider from "../hooks/useErrorProvider";
import BusyButton from "./BusyButton";
import { useNotification } from "./Notification";
import { ResourceType } from "../types";
import useAuth from "../hooks/useAuth";
import If from "./helpers/If";
import useAllSupportAccounts from "../hooks/useAllSupportAccounts";
import usePostNotifications from "../hooks/usePostNotifications";
import routes from "../routes";

interface Props {
  onPosted?: (resource: ResourceType) => void;
  onCancel?: () => void;
  categorySuggestions: Array<string>;
}

export default function NewResourceForm(props: Props) {
  const { categorySuggestions } = props;
  const { user } = useAuth();
  const [isPosting, setPosting] = useState(false);
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();
  const [title, setTitle] = usePersistentState("", "resource.new.title");
  const [comment, setComment] = usePersistentState("", "resource.new.comment");
  const [category, setCategory] = usePersistentState(
    "",
    "resource.new.category"
  );
  const [href, setHref] = usePersistentState("", "resource.new.href");

  const canPost = !isEmpty(trim(title)) && !isEmpty(trim(href));

  const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) =>
    setTitle(e.target.value);
  const onChangeComment = (e: ChangeEvent<HTMLInputElement>) =>
    setComment(e.target.value);
  const onChangeHref = (e: ChangeEvent<HTMLInputElement>) =>
    setHref(e.target.value);
  const onChangeCategory = (e: ChangeEvent<HTMLInputElement>) =>
    setCategory(e.target.value);

  const postToResource = usePostToResources();
  const supportAccounts = useAllSupportAccounts();
  const postNotifications = usePostNotifications();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setPosting(true);

      if (user && user.displayName) {
        const resource = {
          title,
          content: comment,
          href,
          author: user.displayName,
          category,
        };

        await postToResource(resource);

        await postNotifications(
          routes.resources,
          supportAccounts.map((account) => account.uid),
          "resource"
        );

        setComment("");
        setTitle("");
        setHref("");
        setCategory("");
        notify("Your resource has been submitted", "success");

        if (props.onPosted) {
          props.onPosted(resource);
        }
      }
    } catch (e) {
      reportError(`Failed to submit your resource. Please try again later.`);
    } finally {
      setPosting(false);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <FormGroup>
        <Input
          type="text"
          placeholder="Resource title"
          value={title}
          onChange={onChangeTitle}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          placeholder="Online link to your resource"
          value={href}
          onChange={onChangeHref}
        />
      </FormGroup>
      <FormGroup>
        <Input
          type="text"
          placeholder="Resource category"
          value={category}
          onChange={onChangeCategory}
          list="category-suggestions"
        />
      </FormGroup>
      <datalist id="category-suggestions">
        {map(categorySuggestions, (categorySuggestion) => (
          <option>{capitalize(categorySuggestion)}</option>
        ))}
      </datalist>

      <FormGroup>
        <Input
          type="textarea"
          placeholder="Your comments on this resource (optional)"
          rows={10}
          value={comment}
          onChange={onChangeComment}
        />
      </FormGroup>

      <small className="text-muted">
        Your resource will be reviewed by the moderators. If approved, it will
        be visible to all signed in users.
      </small>

      <div className="mt-3 d-flex align-items-center justify-content-end">
        <If test={props.onCancel}>
          <Button
            className="mr-2"
            onClick={props.onCancel}
            color="dark"
            outline
          >
            Cancel
          </Button>
        </If>
        <BusyButton
          isBusy={isPosting}
          color="success"
          type="submit"
          className="shadow"
          disabled={!canPost}
        >
          Submit
        </BusyButton>
      </div>
    </Form>
  );
}
