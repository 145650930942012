import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import * as firebase from "firebase/app";
import useAuth from "./useAuth";
import useErrorProvider from "./useErrorProvider";
import getDataFromDocuments from "../utils/getDataFromDocuments";
import useSupportProfile from "./useSupportProfile";
import { Maybe, ProfileType } from "../types";
import { find, isEmpty } from "lodash";

type ContextProps = {
  profiles: Array<ProfileType>;
  isLoading: boolean;
};

const defaultContextValue = {
  profiles: [],
  isLoading: false,
};

const Context = createContext<ContextProps>(defaultContextValue);
const { Provider } = Context;

interface Props {
  children: React.ReactNode;
}

export function ProfilesProvider({ children }: Props) {
  const [isLoading, setLoading] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const { user } = useAuth();
  const supportProfile = useSupportProfile();
  const { reportError } = useErrorProvider();

  useEffect(() => {
    if (user) {
      setLoading(true);
      const db = firebase.firestore();
      return db.collection("profile").onSnapshot({
        next(result) {
          setLoading(false);
          setProfiles(getDataFromDocuments(result.docs));
        },
        error: (e) => reportError(e, "useAllProfiles"),
      });
    }
  }, [reportError, user, supportProfile]);

  const contextValue = useMemo(
    () => ({ profiles, isLoading }),
    [profiles, isLoading]
  );

  return <Provider value={contextValue}>{children}</Provider>;
}

type HookReturnValue = {
  profiles: Array<ProfileType>;
  isLoading: boolean;
};

export default function useAllProfiles(): HookReturnValue {
  return useContext(Context);
}

export function useProfileByUid(uid: string | undefined): Maybe<ProfileType> {
  const { profiles, isLoading } = useAllProfiles();
  return isEmpty(uid) || isLoading
    ? undefined
    : find(profiles, (p) => p.uid === uid);
}
