import { isEmpty } from "lodash";
import useGeocoder, { GeocodeResult } from "./useGeocoder";
import { useEffect, useState } from "react";

export default function useGeocoding(address: string) {
  const [result, setResult] = useState<GeocodeResult>();
  const lookup = useGeocoder();
  useEffect(() => {
    if (!isEmpty(address)) {
      lookup(address).then(setResult);
    }
  }, [address, lookup]);
  return result;
}
