import React from "react";
import Markdown from "markdown-to-jsx";
import Lead from "./markdown/Lead";
import { APP_NAME } from "./AppName";

const content = `
## Security & Trust
Trust is based on relationships, built over time.

Building those trustworthy relationships **starts with an introduction** and that’s where ${APP_NAME} fits in.
`;

export default function SecurityAndTrust() {
  return (
    <Markdown
      options={{
        overrides: {
          p: {
            component: Lead,
          },
        },
      }}
    >
      {content}
    </Markdown>
  );
}
