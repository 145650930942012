import React from "react";
import { Map, TileLayer, CircleMarker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { LatLng } from "../types";

interface Props {
  center: LatLng;
  zoom?: number;
  marker?: boolean;
  height?: number | string;
  children?: React.ReactNode;
}

export default function MapDisplay({
  center,
  zoom = 5,
  height = 500,
  marker,
  children,
}: Props) {
  return (
    <div className="w-100" style={{ height }}>
      <Map
        center={center}
        zoom={zoom}
        className="w-100 h-100 rounded"
        scrollWheelZoom={false}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        {marker && <CircleMarker radius={10} center={center} />}
        {children}
      </Map>
    </div>
  );
}
