import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Spinner } from "reactstrap";
import { get, isEmpty } from "lodash";
import * as firebase from "firebase/app";

import Layout from "./Layout";
import useRouter from "../../hooks/useRouter";
import useQuery from "../../hooks/useQuery";
import isSupportAccount from "../../utils/isSupportAccount";
import routes from "../../routes";
import useSupportProfile from "../../hooks/useSupportProfile";
import useFormInputState from "../../hooks/useFormInputState";
import BusyButton from "../../components/BusyButton";
import useErrorProvider from "../../hooks/useErrorProvider";
import { useNotification } from "../../components/Notification";

function ChangePassword(props) {
  const { routeTo } = useRouter();
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();

  const [password, onChangePassword] = useFormInputState("");
  const [isBusy, setBusy] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setBusy(true);

    try {
      await firebase.auth().confirmPasswordReset(props.action.code, password);
      notify("Your password has been reset.", "success");
      routeTo(routes.account.signin);
    } catch (e) {
      reportError(e);
    } finally {
      setBusy(false);
    }
  };

  return (
    <div>
      <h1>Change Password</h1>
      <Form onSubmit={onSubmit}>
        <FormGroup>
          <Label for="password">New Password</Label>
          <Input
            type="password"
            name="password"
            onChange={onChangePassword}
            value={password}
            placeholder="Your new password"
            autoFocus
          />
        </FormGroup>
        <div className="d-flex">
          <BusyButton
            isBusy={isBusy}
            color="primary"
            className="ml-auto"
            type="submit"
            disabled={isEmpty(password)}
          >
            Change Password
          </BusyButton>
        </div>
      </Form>
    </div>
  );
}

function DefaultContent() {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="text-uppercase">Loading your account...</div>
      <Spinner color="primary" size="3" />
    </div>
  );
}

const pages = {
  PASSWORD_RESET: ChangePassword,
};

export default function Action() {
  const { routeTo } = useRouter();
  const supportProfile = useSupportProfile();
  const { redirect } = useQuery();

  const action = firebase.auth.ActionCodeURL.parseLink(window.location.href);

  const Content = get(pages, "action.operation", DefaultContent);

  useEffect(() => {
    console.log({ redirect });
    if (!isEmpty(redirect)) {
      routeTo(redirect);
    } else if (isSupportAccount(supportProfile)) {
      routeTo(routes.support.dashboard);
    } else if (supportProfile) {
      routeTo(routes.dashboard);
    }
  }, [redirect, supportProfile, routeTo]);

  return (
    <Layout.Container>
      <Content action={action} />
    </Layout.Container>
  );
}
