import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";
import { FeedItemType } from "../types";

const MERGE = { merge: true };

export default function useUpdateFeedItem() {
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();
  return async (path: string | undefined, updates: FeedItemType) => {
    try {
      if (path) {
        await db.doc(path).set(updates, MERGE);
      }
    } catch (error) {
      reportError(error.message, "useUpdateFeedItem");
    }
  };
}
