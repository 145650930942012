import React, { useState, Fragment, ChangeEvent } from "react";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormText,
} from "reactstrap";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiCalendar } from "@mdi/js";
import { isNaN } from "lodash";

import IfDevelopment from "./helpers/IfDevelopment";
import classes from "../utils/classes";
import parseDate from "../utils/parseDate";
import formatDate from "../utils/formatDate";
import If from "./helpers/If";

const USE_INPUT_TYPE = true;
const USE_DEBUG = false;

type DateChangeEvent = {
  target: {
    value: number | null;
  };
};

interface Props {
  value: string;
  onChange: (value: DateChangeEvent) => void;
  id?: string;
  invalid?: boolean;
}

function isDateInputTypeAvailable() {
  var input = document.createElement("input");
  input.setAttribute("type", "date");

  var notADateValue = "not-a-date";
  input.setAttribute("value", notADateValue);

  return input.value !== notADateValue;
}

const IS_DATE_INPUT_AVAILABLE = isDateInputTypeAvailable();

export default function Date(props: Props) {
  const { value, onChange } = props;

  const dateValue = formatDate(value);

  const [tempDate, setDate] = useState(dateValue);

  const performChange = (d: string) => {
    const newDate = parseDate(d);

    const newValue = moment(value).isValid() ? moment(value) : moment();
    newValue.set(newDate);

    const timestamp = newValue.unix() * 1000;
    onChange({ target: { value: isNaN(timestamp) ? null : timestamp } });
  };

  const onChangeDate = (e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
    performChange(e.target.value);
  };

  return (
    <Fragment>
      <InputGroup
        className={classes({
          "border-danger": props.invalid,
        })}
      >
        <Input
          id={props.id}
          type={USE_INPUT_TYPE ? "date" : "text"}
          value={tempDate}
          onChange={onChangeDate}
          invalid={props.invalid}
          placeholder="YYYY-MM-DD"
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            <Icon path={mdiCalendar} size={0.7} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>

      <If test={!IS_DATE_INPUT_AVAILABLE}>
        <FormText color={props.invalid ? "danger" : "muted"}>
          Format date as YYYY-MM-DD
        </FormText>
      </If>

      <IfDevelopment and={USE_DEBUG}>
        <code>
          value: {value}
          <br />
          {moment(value).toString()}
          <br />
          dateValue: {dateValue} date: {tempDate}
        </code>
      </IfDevelopment>
    </Fragment>
  );
}
