import { mdiMapLegend, mdiNewspaperVariantOutline, mdiReddit } from "@mdi/js";
import Icon from "@mdi/react";
import {
  compact,
  flatMap,
  isEmpty,
  isString,
  map,
  sortBy,
  trim,
  uniq,
} from "lodash";
import React, { Fragment, useState } from "react";
import { Badge, Spinner } from "reactstrap";
import { IntelBriefType } from "../types";
import formatDate from "../utils/formatDate";
import formatHref from "../utils/formatHref";
import toMoment from "../utils/toMoment";
import If from "./helpers/If";

interface Props {
  briefs: Array<IntelBriefType>;
  isLoading: boolean;
}

interface IntelBriefProps {
  brief: IntelBriefType;
}

function getIcon(tags: string[] | undefined) {
  if (tags?.includes("map")) {
    return mdiMapLegend;
  }
  if (tags?.some((tag) => tag.startsWith("r/"))) {
    return mdiReddit;
  }
  return mdiNewspaperVariantOutline;
}

function hasThumbnail(brief: IntelBriefType) {
  return ![undefined, "default", "self"].includes(brief.thumbnailHref);
}

function getTags(brief: IntelBriefType) {
  return isString(brief.tags)
    ? compact(brief.tags?.split(",").map(trim))
    : brief.tags;
}

function IntelBrief({ brief }: IntelBriefProps) {
  const formattedHref = formatHref(brief.href);

  const onOpen = () => {
    window.location.href = brief.href;
  };

  const tags = getTags(brief);

  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onOpen}
      className="border border bg-white rounded p-3 d-flex align-items-start mb-3"
    >
      <div className="mr-2">
        {hasThumbnail(brief) ? (
          <img
            className="border border-dark"
            style={{ width: 72, borderRadius: 5 }}
            src={brief.thumbnailHref}
            alt=""
          />
        ) : (
          <Icon path={getIcon(tags)} size="72px" className="text-light" />
        )}
      </div>

      <div>
        <div className="mb-0">
          <h5 className="mb-0 mr-1 d-inline">{brief.title}</h5>
        </div>
        <div>
          <small>{formattedHref}</small>
          <br />
          <small className="text-muted">{formatDate(brief.created)}</small>
        </div>
        <div className="d-flex flex-gap-1 flex-wrap">
          {map(tags, (tag) => (
            <small key={tag}>
              <Badge color="light" className="shadow-sm">
                {tag}
              </Badge>
            </small>
          ))}
        </div>
      </div>
    </div>
  );
}

function getSortKey(brief: IntelBriefType) {
  const m = toMoment(brief.created);
  // Negate the epoch time to sort in descending order
  return m.isValid() ? -m.toDate().getTime() : 0;
}

interface TagFilterProps {
  tag: string;
  onClick: (tag: string) => void;
  isSelected: boolean;
}

function TagFilter({ tag, onClick, isSelected }: TagFilterProps) {
  const onClickBadge = () => {
    onClick(tag);
  };
  return (
    <Badge
      key={tag}
      color={isSelected ? "dark" : "light"}
      className="mr-1 clickable"
      onClick={onClickBadge}
    >
      {tag}
      <If test={isSelected}>&nbsp;&times;</If>
    </Badge>
  );
}

export default function IntelBriefList({ briefs, isLoading }: Props) {
  const sortedBriefs = sortBy(briefs, (brief) => getSortKey(brief));

  const tags = sortBy(
    compact(uniq(flatMap(briefs, (brief) => getTags(brief))))
  );

  const [selectedTag, setSelectedTag] = useState<string>();

  const onClickTag = (tag: string) => {
    setSelectedTag((current) => (tag === current ? undefined : tag));
  };

  const filteredBriefs: IntelBriefType[] = selectedTag
    ? sortedBriefs.filter((brief) => getTags(brief)?.includes(selectedTag))
    : sortedBriefs;

  const items = map(filteredBriefs, (brief) => (
    <IntelBrief key={brief.id} brief={brief} />
  ));

  return (
    <Fragment>
      <If test={isLoading}>
        <div className="h-100 d-flex align-items-center justify-content-center">
          <Spinner size="sm" color="primary" />
        </div>
      </If>

      <If test={!isEmpty(tags)}>
        <div className="mb-3">
          {map(tags, (tag) => (
            <TagFilter
              tag={tag}
              key={tag}
              onClick={onClickTag}
              isSelected={tag === selectedTag}
            />
          ))}
        </div>
      </If>

      {items}
    </Fragment>
  );
}
