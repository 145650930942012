import * as firebase from "firebase/app";
import useErrorProvider from "./useErrorProvider";

import useAuth from "./useAuth";
import { ResourceType } from "../types";
import useSupportProfile from "./useSupportProfile";
import isSupportAccount from "../utils/isSupportAccount";

export default function usePostToResources() {
  const { user } = useAuth();
  const { reportError } = useErrorProvider();
  const db = firebase.firestore();

  const supportProfile = useSupportProfile();

  // Automatically approve resources
  // submitted by support users
  const approved = isSupportAccount(supportProfile);

  return async (resource: ResourceType) => {
    if (user) {
      try {
        const doc = await db.collection("resources").add({
          ...resource,
          approved,
          uid: user.uid,
          created: firebase.firestore.Timestamp.now(),
        });
        return doc.id;
      } catch (e) {
        reportError(e, "usePostToResources");
      }
    }
    return Promise.resolve();
  };
}
