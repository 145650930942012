import React from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import IntelBriefList from "../components/IntelBriefList";
import { DESCRIPTIONS } from "../components/SecondaryNavigation";
import useAllIntel from "../hooks/useAllIntel";
import useAuth from "../hooks/useAuth";
import Layout from "./Layout";
import MainLayout from "./MainLayout";

interface GuestLayoutProps {
  children: React.ReactNode;
}

function GuestLayout({ children }: GuestLayoutProps) {
  return (
    <Layout>
      <Container className="my-5">
        <Row>
          <Col>{children}</Col>
        </Row>
      </Container>
    </Layout>
  );
}

const LoadingPage = () => (
  <div className="min-vh-100 w-100 d-flex justify-content-center align-items-center">
    <Spinner color="primary" size="3" />
  </div>
);

export default function IntelPage() {
  const { isAuthenticated, user, isLoading: isLoadingAuth } = useAuth();
  const { briefs, isLoading: isLoadingIntel } = useAllIntel();

  if (isLoadingAuth) {
    return <LoadingPage />;
  }

  const PageLayout = isAuthenticated && user ? MainLayout : GuestLayout;
  return (
    <PageLayout>
      <h1 className="h4">Intel</h1>
      <div className="mb-3 text-muted lead">{DESCRIPTIONS.intel}</div>
      <IntelBriefList briefs={briefs} isLoading={isLoadingIntel} />
    </PageLayout>
  );
}
