import React, { FormEvent, ChangeEvent, useState } from "react";
import { Input, Form, Button } from "reactstrap";
import { isEmpty, trim } from "lodash";
import useErrorProvider from "../hooks/useErrorProvider";
import BusyButton from "./BusyButton";
import { useNotification } from "./Notification";
import useUpdateFeedItem from "../hooks/useUpdateFeedItem";
import { FeedItemType } from "../types";

interface Props {
  username: string;
  item: FeedItemType;
  onUpdated: () => void;
  onCancel: () => void;
}

export default function EditFeedItemForm({
  username,
  item,
  onUpdated,
  onCancel,
}: Props) {
  const [isPosting, setPosting] = useState(false);
  const { reportError } = useErrorProvider();
  const { notify } = useNotification();
  const [message, setMessage] = useState(item.message);

  const canPost = !isEmpty(trim(message));

  const onChangeMessage = (e: ChangeEvent<HTMLInputElement>) =>
    setMessage(e.target.value);

  const updateFeedItem = useUpdateFeedItem();

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (item.id) {
      try {
        setPosting(true);
        await updateFeedItem(item.path, {
          message,
          author: username,
        });

        notify("Your message has been updated", "success");

        onUpdated();
      } catch (e) {
        reportError(`Failed to update your message. Please try again later.`);
      } finally {
        setPosting(false);
      }
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        type="textarea"
        autoFocus
        placeholder="What's on your mind?"
        rows={10}
        value={message}
        onChange={onChangeMessage}
      />
      <div className="mt-3 d-flex align-items-center justify-content-between">
        <small className="text-muted">
          Your thoughts will be visible to all signed in users.
        </small>
        <div className="d-flex ml-3">
          <BusyButton
            isBusy={isPosting}
            color="success"
            type="submit"
            className="shadow"
            disabled={!canPost}
          >
            Update
          </BusyButton>
          <Button color="dark" outline onClick={onCancel} className="ml-2">
            Cancel
          </Button>
        </div>
      </div>
    </Form>
  );
}
