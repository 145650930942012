import React, { ChangeEvent } from "react";
import { mdiCameraPlus } from "@mdi/js";
import Icon from "@mdi/react";
import useAssetFromStorage from "../hooks/useAssetFromStorage";
import { isEmpty } from "lodash";

interface Props {
  avatarFilename: string | undefined;
  onChange: (file: File) => void;
}

const avatarPreviewStyle = {
  width: 64,
  height: 64,
  position: "relative" as "relative",
  zIndex: 1,
};

function avatarImageStyle(avatarUrl: string | undefined) {
  return {
    width: "100%",
    height: "100%",
    backgroundImage: !isEmpty(avatarUrl) ? `url(${avatarUrl})` : "none",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };
}

const avatarPickerStyle = {
  position: "absolute" as "absolute",
  opacity: 0,
  zIndex: 2,
  top: 0,
  left: 0,
  height: 64,
  width: 64,
  cursor: "pointer",
};

export default function AvatarPreview({ avatarFilename, onChange }: Props) {
  const avatarUrl = useAssetFromStorage(avatarFilename);
  const onChangeFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const file: File = (e.target.files as FileList)[0];
    onChange(file);
  };

  return (
    <div className="position-relative">
      <div
        style={avatarPreviewStyle}
        className="rounded-circle bg-dark border border-dark text-white d-flex align-items-center justify-content-center p-0"
      >
        {avatarFilename ? (
          <div
            style={avatarImageStyle(avatarUrl)}
            className="rounded-circle d-flex align-items-center justify-content-center"
          >
            <Icon path={mdiCameraPlus} size={0.7} />
          </div>
        ) : (
          <Icon path={mdiCameraPlus} size={1} />
        )}
      </div>
      <input
        type="file"
        style={avatarPickerStyle}
        onChange={onChangeFileInput}
      />
    </div>
  );
}
