import React, {
  FormEvent,
  useRef,
  useEffect,
  useMemo,
  KeyboardEvent,
} from "react";
import { map, filter, size } from "lodash";

import { DirectMessageType } from "../types";
import DirectMessage from "./DirectMessage";
import { Form, Button } from "reactstrap";
import Icon from "@mdi/react";
import { mdiSend, mdiChevronLeft } from "@mdi/js";
import useFormInputState from "../hooks/useFormInputState";
import useConversation from "../hooks/useConversation";
import useProfiles from "../hooks/useProfiles";
import useAuth from "../hooks/useAuth";
import AvatarDisplay from "./AvatarDisplay";
import getNameOrDisplayName from "../utils/getNameOrDisplayName";
import routes from "../routes";
import If from "./helpers/If";

interface HeaderProps {
  conversation: string;
}

function participantStyle(index: number) {
  return { marginLeft: index > 0 ? -8 : 0 };
}

function Header({ conversation }: HeaderProps) {
  const { participants } = useConversation(conversation);
  const { user } = useAuth();
  const otherParticipants = useMemo(
    () => (user ? filter(participants, (p) => p !== user.uid) : []),
    [participants, user]
  );

  const { profiles } = useProfiles(otherParticipants);

  return (
    <div className="d-flex align-items-center justify-content-between p-2 border-bottom border-light">
      <div className="d-flex align-items-center">
        <Button
          href={routes.conversations}
          size="sm"
          outline
          color="link"
          className="p-1"
        >
          <Icon path={mdiChevronLeft} size={1} />
        </Button>

        <div style={{ height: 24, width: 0 }} className="ml-2" />

        <div className="d-flex align-items-center justify-content-between">
          {map(profiles, (profile, index) => (
            <div
              key={index}
              className="d-flex align-items-center"
              style={participantStyle(index)}
            >
              <AvatarDisplay
                avatarFilename={profile.avatarFilename}
                size={24}
                username={profile.name ?? profile.id}
              />
              <If test={size(profiles) === 1}>
                <div className="ml-2">{getNameOrDisplayName(profile)}</div>
              </If>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

interface Props {
  conversation: string;
  messages: DirectMessageType[];
  currentUid: string;
  onSendMessage: (message: string) => void;
}

export default function DirectMessages({
  conversation,
  messages,
  currentUid,
  onSendMessage,
}: Props) {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollTargetRef = useRef<HTMLDivElement>(null);
  const [message, onChangeMessage] = useFormInputState("");

  useEffect(() => {
    if (scrollContainerRef.current && scrollTargetRef.current) {
      scrollContainerRef.current.scrollTo({
        top: scrollContainerRef.current.scrollHeight,
      });
    }
  }, [messages]);

  const sendMessage = () => {
    onSendMessage(message);

    onChangeMessage({ target: { value: "" } });
  };

  const onKeydown = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.ctrlKey) {
      sendMessage();
    }
  };

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <div className="d-flex flex-column justify-content-between align-items-stretch h-100 border-left border-light">
      <Header conversation={conversation} />

      <div className="overflow-auto h-100" ref={scrollContainerRef}>
        <div className="px-1 py-3 h-100" ref={scrollTargetRef}>
          <div className="text-center">
            <small className="text-muted">
              This is the beginning of your <strong>private</strong>{" "}
              conversation...
            </small>
          </div>

          {map(messages, (message) => (
            <DirectMessage
              key={message.id}
              conversation={conversation}
              message={message}
              alignment={currentUid === message.sender ? "right" : "left"}
            />
          ))}

          <div className="mb-3" />
        </div>
      </div>

      <Form
        onSubmit={onSubmit}
        className="d-flex align-items-start bg-white p-2 border-top border-light"
      >
        <textarea
          className="w-100 border-0 no-outline"
          placeholder="Your message..."
          value={message}
          onChange={onChangeMessage}
          onKeyDown={onKeydown}
        />
        <Button color="success" type="submit" className="ml-1 p-2 rounded-sm">
          <Icon path={mdiSend} size={0.7} />
        </Button>
      </Form>
    </div>
  );
}
